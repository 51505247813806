import type { PropsWithChildren } from "react";
import type { GridLayout } from "@/components/planner/grid-layout";

type EmptyRowProps = PropsWithChildren<{
  gridLayout: GridLayout;
}>;

export function EmptyRow({ gridLayout, children }: EmptyRowProps) {
  return (
    <div
      className="flex items-center justify-center p-2 border rounded-b-md"
      style={{
        gridColumn: `1 / span ${gridLayout.getColumnCount()}`,
      }}
    >
      {children}
    </div>
  );
}
