import type { TFunction } from "i18next";
import { type BookableResponseDTO, resourceUsageType } from "@/client/private";
import {
  type DataTableFilterField,
  searchTermColumnId,
} from "@/types/table.ts";

export function getBookableTableFilters(
  t: TFunction,
): Array<DataTableFilterField<BookableResponseDTO>> {
  return [
    {
      value: searchTermColumnId,
      placeholder: t("bookables.search_placeholder"),
    },
    {
      label: t("bookables.properties.resource_usage_type"),
      value: "resourceUsageType",
      options: Object.values(resourceUsageType).map((type) => {
        return {
          label: t(`bookables.resource_usage_type.${type.toLowerCase()}`),
          value: type,
        };
      }),
    },
  ];
}
