import { Link } from "@tanstack/react-router";
import {
  CalendarCheck,
  CalendarClock,
  Component,
  ConciergeBell,
  Construction,
  LineChart,
  Package2,
  PanelLeft,
  SatelliteDish,
  Users2,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button.tsx";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet.tsx";

export const MobileSideMenu = () => {
  const { t } = useTranslation();

  const linkClassName =
    "flex items-center gap-4 px-2.5 text-muted-foreground [&.active]:text-foreground hover:text-foreground";

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button size="icon" variant="outline" className="sm:hidden">
          <PanelLeft className="h-5 w-5" />
          <span className="sr-only">{t("menu.toggle")}</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="sm:max-w-xs">
        <nav className="grid gap-6 text-lg font-medium">
          <SheetClose asChild>
            <Link
              to="/dashboard"
              className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
            >
              <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">CBRM</span>
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/planner" className={linkClassName}>
              <CalendarClock className="h-5 w-5" />
              {t("planner.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/reservations" className={linkClassName}>
              <CalendarCheck className="h-5 w-5" />
              {t("reservations.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/bookables" className={linkClassName}>
              <ConciergeBell className="h-5 w-5" />
              {t("bookables.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/resources" className={linkClassName}>
              <Component className="h-5 w-5" />
              {t("resources.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/unavailability" className={linkClassName}>
              <Construction className="h-5 w-5" />
              {t("unavailability.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/employees" className={linkClassName}>
              <Users2 className="h-5 w-5" />
              {t("employees.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link to="/channels" className={linkClassName}>
              <SatelliteDish className="h-5 w-5" />
              {t("channels.title")}
            </Link>
          </SheetClose>
          <SheetClose asChild>
            <Link
              to="/settings"
              className="flex items-center gap-4 px-2.5 text-muted-foreground [&.active]:text-foreground hover:text-foreground"
            >
              <LineChart className="h-5 w-5" />
              {t("settings.title")}
            </Link>
          </SheetClose>
        </nav>
      </SheetContent>
    </Sheet>
  );
};
