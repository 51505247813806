import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ChannelResponseDTO } from "@/client/private";
import { updateChannelsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import type { ChannelSchemaValues } from "@/components/channels/channel-schema";
import { createChannelSchema } from "@/components/channels/channel-schema";
import { ApiKeyForm } from "@/components/channels/edit/ApiKeyForm";
import {
  FormCheckbox,
  FormContainer,
  FormDuration,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditChannelFormProps {
  channel: ChannelResponseDTO;
}

export function EditChannelForm({ channel }: EditChannelFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateChannelsMutation() });

  const schema = createChannelSchema(t);
  const form = useZodForm({ schema, values: channel });

  const handleSubmit = (values: ChannelSchemaValues) => {
    update.mutate(
      {
        path: { id: channel.id },
        body: {
          ...values,
          active: values.active === true,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("channels.channel") }),
          );
          navigate({ to: "/channels" });
        },
        onError: () =>
          toast.error(t("common.edit_error", { item: t("channels.channel") })),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={update}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer
      title={`${t("channels.channel")} ${channel.url}`}
      sidePanel={<ApiKeyForm channelId={channel.id} />}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("channels.edit.title")}</CardTitle>
          <CardDescription>{t("channels.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="url" schema={schema} />
            <FormCheckbox name="active" schema={schema} />
            <FormDuration name="bookingRange" schema={schema} />
            <FormDuration name="prepareTime" schema={schema} />
          </FormContainer>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
