import type { LabelHTMLAttributes } from "react";
import { FormLabel } from "@/components/ui/form";

type FormItemLabelProps = {
  label?: string;
  isRequired?: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

export function FormItemLabel({
  label,
  isRequired,
  ...props
}: FormItemLabelProps) {
  return (
    <FormLabel {...props}>
      {label}
      {isRequired && <span className="text-destructive"> *</span>}
    </FormLabel>
  );
}
