import type {
  SomeZodObject,
  ZodDefaultDef,
  ZodFirstPartySchemaTypes,
  ZodRawShape,
} from "zod";
import {
  ZodDefault,
  ZodEffects,
  ZodEnum,
  ZodNullable,
  ZodObject,
  ZodOptional,
} from "zod";

/**
 * Get basic properties about the schema field like the label and if it a required field.
 */
export function getSchemaProperties<T extends ZodRawShape>(
  schema: ZodObject<T> | ZodEffects<ZodObject<T>>,
  fieldName: string,
) {
  const fieldSchema =
    schema instanceof ZodObject
      ? schema.shape[fieldName]
      : getBaseObjectSchema(schema).shape[fieldName];

  if (!fieldSchema) {
    throw new Error(`Field ${fieldName} not found in schema`);
  }

  const baseSchema = getBaseSchema(fieldSchema);

  return {
    label: fieldSchema.description ?? baseSchema.description ?? fieldName,
    isRequired: getIsRequired(fieldSchema),
    ...getSchemaChecks(baseSchema),
    fieldSchema,
    baseSchema,
  };
}

function getIsRequired(schema: ZodFirstPartySchemaTypes) {
  return !(schema instanceof ZodOptional || schema instanceof ZodNullable);
}

function getSchemaChecks(schema: ZodFirstPartySchemaTypes) {
  let min: number | undefined;
  let max: number | undefined;
  if ("checks" in schema._def) {
    const { checks } = schema._def;

    for (const check of checks) {
      if (check.kind === "min") {
        min = check.value as number;
      }
      if (check.kind === "max") {
        max = check.value as number;
      }
    }
  }

  return {
    min,
    max,
  };
}

/**
 * Get default values for all fields in a Zod schema.
 * Does not support nested objects.
 */
export function getSchemaDefaultValues(schema: ZodFirstPartySchemaTypes) {
  if (schema instanceof ZodObject) {
    return Object.fromEntries(
      Object.entries(schema.shape).map(([key, value]) => [
        key,
        getDefaultValue(value as ZodFirstPartySchemaTypes),
      ]),
    );
  }

  return getDefaultValue(schema);
}

/**
 * Get the allowed values for an enum schema.
 */
export function getEnumValues(schema: ZodFirstPartySchemaTypes) {
  const baseSchema = getBaseSchema(schema);

  if (baseSchema instanceof ZodEnum) {
    return baseSchema._def.values as string[];
  } else {
    return [];
  }
}

export function getBaseSchema(
  schema: ZodFirstPartySchemaTypes,
): ZodFirstPartySchemaTypes {
  if (schema instanceof ZodOptional || schema instanceof ZodNullable) {
    return getBaseSchema(schema.unwrap());
  } else if (schema instanceof ZodEffects) {
    return getBaseSchema(schema.innerType());
  } else if (schema instanceof ZodDefault) {
    return getBaseSchema(schema._def.innerType);
  } else {
    return schema;
  }
}

export function getBaseObjectSchema(
  schema: ZodFirstPartySchemaTypes,
): SomeZodObject {
  const baseSchema = getBaseSchema(schema);

  if (baseSchema instanceof ZodObject) {
    return baseSchema;
  }

  throw new Error("Schema is not an object");
}

export function getDefaultValue(
  schema: ZodFirstPartySchemaTypes,
): ReturnType<ZodDefaultDef["defaultValue"]> {
  if (schema instanceof ZodDefault) {
    return (schema as ZodDefault<ZodFirstPartySchemaTypes>)._def.defaultValue();
  } else if (schema instanceof ZodOptional || schema instanceof ZodNullable) {
    return getDefaultValue(schema.unwrap());
  } else if (schema instanceof ZodEffects) {
    return getDefaultValue(schema.innerType());
  } else if (schema instanceof ZodObject) {
    return Object.fromEntries(
      Object.entries(schema.shape).map(([key, value]) => [
        key,
        getDefaultValue(value as ZodFirstPartySchemaTypes),
      ]),
    );
  }

  return undefined;
}

export function getFormFieldName(path: string[] | undefined, name: string) {
  return [...(path ?? []), name].join(".");
}
