import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyRow } from "@/components/planner/EmptyRow";
import { HeaderRow } from "@/components/planner/HeaderRow";
import { NavigationRow } from "@/components/planner/NavigationRow";
import ReservationCell from "@/components/planner/ReservationCell";
import { ResourceRow } from "@/components/planner/ResourceRow";
import ZoomLevelSelect from "@/components/planner/ZoomLevelSelect";
import {
  GridLayout,
  SLOT_HEIGHT_IN_REM,
  createTableRowMapping,
  createTimeSlots,
} from "@/components/planner/grid-layout";
import { useResourceReservationData } from "@/components/planner/use-resource-reservation-data";
import { getZoomLevel } from "@/components/planner/zoom-level";
import { Skeleton } from "@/components/ui/skeleton";
import { useElementSize } from "@/hooks/use-element-size";
import { repeatElement } from "@/lib/repeat-element";

type ResourceReservationGridProps = {
  initialDate?: Date;
  resourceIdsToShow?: string[];
};

export default function ResourceReservationGrid({
  initialDate,
  resourceIdsToShow,
}: ResourceReservationGridProps) {
  const { t } = useTranslation();
  const [zoomLevel, setZoomLevel] = useState(getZoomLevel("0"));
  const [firstSlotStart, setFirstSlotStart] = useState(
    zoomLevel.getStartSlot(),
  );
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { elementSize: containerSize } = useElementSize(containerRef);

  const { timeSlots, lastSlotEnd } = createTimeSlots(
    firstSlotStart,
    zoomLevel.slotSizeInMinutes,
    containerSize,
  );

  const { resources, reservations, isResourcesLoading } =
    useResourceReservationData(
      firstSlotStart,
      lastSlotEnd,
      resourceIdsToShow ?? [],
    );

  const { tableRowMapping, resourceLaneMapping } = createTableRowMapping(
    resources,
    reservations,
  );

  const gridLayout = new GridLayout(
    firstSlotStart,
    timeSlots,
    firstSlotStart,
    lastSlotEnd,
    zoomLevel,
    tableRowMapping,
    resourceLaneMapping,
  );

  useEffect(() => {
    if (initialDate) {
      setFirstSlotStart(zoomLevel.getDateSelectedStartSlot(initialDate));
    }
  }, [initialDate, zoomLevel]);

  return (
    <div ref={containerRef} className="flex flex-col max-h-dvh">
      <div className="flex justify-end flex-wrap gap-1 mb-4">
        <ZoomLevelSelect
          selectedId={gridLayout.zoomLevel.id}
          onSelect={setZoomLevel}
        />
      </div>
      <div
        className="grid"
        style={{
          gridTemplateColumns: `repeat(${gridLayout.getColumnCount()}, minmax(0, 1fr))`,
          gridTemplateRows: `repeat(2, minmax(0, max-content))`,
        }}
      >
        <NavigationRow
          gridLayout={gridLayout}
          onSetFirstSlotStart={setFirstSlotStart}
        />
        <HeaderRow gridLayout={gridLayout} />
      </div>
      <div className="overflow-auto grow">
        <div
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${gridLayout.getColumnCount()}, minmax(0, 1fr))`,
            gridAutoRows: `minmax(${SLOT_HEIGHT_IN_REM}rem, max-content)`,
          }}
        >
          {isResourcesLoading && (
            <EmptyRow gridLayout={gridLayout}>
              <div className="flex gap-2 h-full w-full">
                {repeatElement(
                  <Skeleton className="h-full w-full" />,
                  gridLayout.getColumnCount(),
                )}
              </div>
            </EmptyRow>
          )}
          {resources.length === 0 && !isResourcesLoading && (
            <EmptyRow gridLayout={gridLayout}>
              {t("reservations.resource_select.no_resources")}
            </EmptyRow>
          )}
          {resources.map((resource) => (
            <ResourceRow
              key={resource.id}
              resource={resource}
              gridLayout={gridLayout}
            />
          ))}
          {reservations.map((reservation) => (
            <ReservationCell
              key={reservation.key}
              reservation={reservation}
              gridLayout={gridLayout}
              isRequest={false}
            />
          ))}
          {/* {assignedRequests.map((request) => {
              return (
                <ReservationCell
                  key={request.key}
                  reservation={request}
                  isRequest={true}
                  gridLayout={gridLayout}
                />
              );
            })} */}
        </div>
      </div>
    </div>
  );
}
