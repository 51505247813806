import { createFileRoute } from "@tanstack/react-router";
import { PasswordRecovery } from "@/pages/PasswordRecovery";

export const Route = createFileRoute("/password-recovery")({
  validateSearch: (search) => {
    return {
      email: String(search["email"] || ""),
    };
  },
  component: PasswordRecovery,
});
