import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { startOfDay } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { operation } from "@/client/private";
import { createOpeningHoursMutation } from "@/client/private/@tanstack/react-query.gen";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { OperationSelect } from "@/components/opening-hours/OperationSelect";
import type { OpeningHourFormData } from "@/components/opening-hours/models/opening-hour-form-data.model";
import { RecurrenceForm } from "@/components/recurrence/RecurrenceForm";
import { createRecurringRule } from "@/components/recurrence/functions/create-recurring-rule";
import { getDurationFromStartAndEndTime } from "@/components/recurrence/functions/duration";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Form, FormField } from "@/components/ui/form";

export function AddOpeningHourForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const create = useMutation({
    ...createOpeningHoursMutation(),
  });

  const form = useForm<OpeningHourFormData>({
    defaultValues: {
      startDate: startOfDay(new Date()),
      operation: operation.OPEN,
      startTime: "08:00",
      endTime: "18:00",
      frequency: "DAILY",
      interval: "1",
      weekDays: ["MO", "TU", "WE", "TH", "FR"],
    },
    mode: "onChange",
  });

  const handleSubmit = (values: OpeningHourFormData) => {
    create.mutate(
      {
        body: {
          operation: values.operation,
          startAt: values.startDate,
          recurringRule: createRecurringRule(values),
          recurringDuration: getDurationFromStartAndEndTime(
            values.startTime,
            values.endTime,
          ),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", {
              item: t("opening_hours.opening_hour"),
            }),
          );
          navigate({ to: "/settings/opening-hours" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("opening_hours.opening_hour") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={create.isPending || !form.formState.isValid}
      onClick={() => handleSubmit(form.getValues())}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemContainer title={t("opening_hours.add.title")} buttons={buttons}>
      <Card>
        <CardHeader>
          <CardTitle>{t("opening_hours.opening_hour")}</CardTitle>
          <CardDescription>
            {t("opening_hours.add.description")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              className="space-y-5"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <FormField
                control={form.control}
                name="operation"
                render={({ field }) => (
                  <OperationSelect
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              ></FormField>
              <RecurrenceForm />
              <Button
                type="submit"
                disabled={create.isPending || !form.formState.isValid}
              >
                {t("common.save")}
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
