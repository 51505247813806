import type { ColumnDef, Row } from "@tanstack/react-table";
import type { ReactNode } from "react";
import type { ListSettingDTO } from "@/client/private";
import { DataTable } from "@/components/data-table/data-table";
import { DataTableSkeleton } from "@/components/data-table/data-table-skeleton";
import { DataTableToolbar } from "@/components/data-table/data-table-toolbar";
import { Separator } from "@/components/ui/separator";
import { useDataTable, type RequiredRowProps } from "@/hooks/use-data-table";
import type { DataTableFilterField } from "@/types/table";

interface DataTableLayoutWrapperProps<T> {
  above?: ReactNode;
  toolbar: ReactNode;
  columns: Array<ColumnDef<T>>;
  content: Array<T>;
  totalPages: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  onRowClick?: (row: Row<T>) => void;
  filterFields: Array<DataTableFilterField<T>>;
  listSettings?: ListSettingDTO;
  onListSettingsChange: (newListSettings: ListSettingDTO) => void;
  isFetching: boolean;
}

interface DataTableLayout<T> {
  above?: ReactNode;
  toolbar: ReactNode;
  columns: Array<ColumnDef<T>>;
  content: Array<T>;
  totalPages: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  onRowClick?: (row: Row<T>) => void;
  filterFields: Array<DataTableFilterField<T>>;
  listSettings: ListSettingDTO;
  onListSettingsChange: (newListSettings: ListSettingDTO) => void;
  isFetching: boolean;
}

export const DataTableLayoutWrapper = <T extends RequiredRowProps>({
  content,
  listSettings,
  ...rest
}: DataTableLayoutWrapperProps<T>) => {
  if (!content || !listSettings) {
    return (
      <DataTableSkeleton
        columnCount={3}
        searchableColumnCount={1}
        filterableColumnCount={2}
        cellWidths={["10rem", "40rem", "12rem", "12rem", "8rem"]}
        shrinkZero
      />
    );
  }

  return (
    <DataTableLayout {...rest} content={content} listSettings={listSettings} />
  );
};

const DataTableLayout = <T extends RequiredRowProps>({
  content,
  columns,
  totalPages,
  filterFields,
  pageIndex,
  setPageIndex,
  onRowClick,
  listSettings,
  onListSettingsChange,
  above,
  toolbar,
  isFetching,
}: DataTableLayout<T>) => {
  const { table } = useDataTable<T, T>({
    data: content,
    columns,
    totalPages,
    pageIndex,
    setPageIndex,
    listSettings,
    onListSettingsChange,
  });

  return (
    <>
      {above}
      <Separator />
      <DataTableToolbar
        table={table}
        isLoading={isFetching}
        filterFields={filterFields}
      >
        {toolbar}
      </DataTableToolbar>
      <DataTable table={table} onRowClick={onRowClick} />
    </>
  );
};
