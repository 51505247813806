import type * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import type { ComponentProps } from "react";
import { useFormContext } from "react-hook-form";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import { getFormFieldName, getSchemaProperties } from "@/components/form/utils";
import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
} from "@/components/ui/form";

type FormCheckboxProps<T extends ZodShape> = ComponentProps<
  typeof CheckboxPrimitive.Root
> &
  FormFieldProps<T>;

export function FormCheckbox<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  ...props
}: FormCheckboxProps<T>) {
  const form = useFormContext();

  const { label, isRequired } = getSchemaProperties(schema, name);

  return (
    <FormField
      name={getFormFieldName(path, name)}
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <div className="mb-3 flex items-center gap-3">
            <FormControl>
              <Checkbox
                name={field.name}
                checked={field.value ?? false}
                ref={field.ref}
                onCheckedChange={field.onChange}
                onBlur={field.onBlur}
                {...props}
              />
            </FormControl>
            <FormItemLabel label={label} isRequired={isRequired} />
          </div>
          <FormDescription>{description}</FormDescription>
        </FormItem>
      )}
    />
  );
}
