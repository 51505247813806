import { useTranslation } from "react-i18next";
import { PasswordRecoveryForm } from "@/components/auth/PasswordRecoveryForm";
import { Authentication } from "@/components/layout/Authentication";

export const PasswordRecovery = () => {
  return (
    <Authentication>
      <Header />
      <PasswordRecoveryForm />
    </Authentication>
  );
};

function Header() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-2 text-center">
      <h1 className="text-2xl font-semibold tracking-tight">
        {t("password_recovery.title")}
      </h1>
      <p className="text-sm text-muted-foreground">
        {t("password_recovery.description")}
      </p>
    </div>
  );
}
