export type Option = {
  label: string;
  value: string;
  count?: number;
  icon?: React.ComponentType<{ className?: string }>;
};

export type DataTableFilterField<TData> =
  | DataTableSearchTermFilterField
  | DataTableListFilterField<TData>;

export type DataTableSearchTermFilterField = {
  placeholder: string;
  value: typeof searchTermColumnId;
};

export type DataTableListFilterField<TData> = {
  label: string;
  value: keyof TData;
  options: Array<Option>;
};

export type DataTableFilterOption<TData> = {
  id: string;
  label: string;
  value: keyof TData;
  options: Array<Option>;
  filterValues?: Array<string>;
  filterOperator?: string;
  isMulti?: boolean;
};

export const searchTermColumnId = "searchTerm";
