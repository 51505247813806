import type { Row } from "@tanstack/table-core";
import { useTranslation } from "react-i18next";
import { getEndTimeFromStartAndDuration } from "@/components/recurrence/functions/duration";
import { parseRecurringRule } from "@/components/recurrence/functions/parse-rule";
import { frequencyToTranslationKey } from "@/components/recurrence/models/frequency-to-translation-key.model";
import type { WeekDay } from "@/components/recurrence/models/week-day.model";
import { useDateFormat } from "@/hooks/use-date-format";

interface DataTableDurationCell<T> {
  row: Row<T>;
  ruleProperty: Extract<keyof T, string>;
  durationProperty: Extract<keyof T, string>;
}

export const DataTableRecurrenceCell = <T,>({
  row,
  ruleProperty,
  durationProperty,
}: DataTableDurationCell<T>) => {
  const { t } = useTranslation();
  const { formatDateUtc, weekStartsOn } = useDateFormat();

  const rule = row.getValue<string>(ruleProperty);
  const { frequency, interval, weekDays, startTime } = parseRecurringRule(rule);
  const duration = row.original[durationProperty] as string;
  const endTime = getEndTimeFromStartAndDuration(startTime, duration);
  const endTimeSuffix =
    endTime === "24:00" ? ` ${t("recurrence.the_next_day")}` : "";

  const frequencyLabel = t(frequencyToTranslationKey[frequency], {
    count: parseInt(interval, 10),
  });
  const daysLabel = weekDays
    .sort(
      (a, b) =>
        getLocaleWeekDayIndex(a, weekStartsOn) -
        getLocaleWeekDayIndex(b, weekStartsOn),
    )
    .map((day) => t(`date_time.week_days.${day.toLowerCase()}`))
    .join(", ");
  const humanReadableRecurrence = t(
    "recurrence.pattern_human_readable_recurrence",
    {
      frequency: frequencyLabel,
      days: daysLabel,
    },
  );
  const humanReadableTime = t("recurrence.pattern_human_readable_time", {
    startTime: formatDateUtc(`2000-01-01T${startTime}:00Z`, "p"),
    endTime: formatDateUtc(`2000-01-01T${endTime}:00Z`, "p") + endTimeSuffix,
  });

  return (
    <>
      {humanReadableRecurrence}
      <br />
      {humanReadableTime}
    </>
  );
};

const getLocaleWeekDayIndex = (weekDay: WeekDay, startStartOn: number) => {
  return (weekDayIndex[weekDay] + 7 - startStartOn) % 7;
};

const weekDayIndex: Record<WeekDay, number> = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
  SA: 5,
  SU: 6,
};
