import { zodResolver } from "@hookform/resolvers/zod";
import { useId } from "react";
import { useForm } from "react-hook-form";
import type { ZodTypeAny, infer as zodInfer } from "zod";
import type { ZodForm } from "@/components/form/types";
import { getSchemaDefaultValues } from "@/components/form/utils";

export function useZodForm<T extends ZodTypeAny>({
  schema,
  values,
}: {
  schema: T;
  values?: zodInfer<T>;
}): ZodForm<zodInfer<T>> {
  const id = useId();

  const form = useForm({
    defaultValues: getSchemaDefaultValues(schema),
    values,
    resolver: zodResolver(schema),
    mode: "onTouched",
  });

  return { ...form, id };
}
