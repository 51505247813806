import type { TFunction } from "i18next";
import type { ResourceResponseDTO } from "@/client/private";
import {
  searchTermColumnId,
  type DataTableFilterField,
} from "@/types/table.ts";

export function getResourceTableFilters(
  t: TFunction,
): Array<DataTableFilterField<ResourceResponseDTO>> {
  return [
    {
      value: searchTermColumnId,
      placeholder: t("resources.search_placeholder"),
    },
  ];
}
