import type { PropsWithChildren } from "react";
import {
  SLOT_HEIGHT_IN_REM,
  type GridLayout,
} from "@/components/planner/grid-layout";

type ResourceNameCellProps = PropsWithChildren<{
  resourceId: string;
  gridLayout: GridLayout;
}>;

export function ResourceCell({
  resourceId,
  gridLayout,
  children,
}: ResourceNameCellProps) {
  const gridRow = gridLayout.getRow(resourceId);
  const laneCount = gridLayout.getLanes(resourceId);
  const isBottomLeftCell = gridLayout.isLastResource(resourceId);
  const leftCellBorder = isBottomLeftCell ? "rounded-bl-md border-b" : "";

  return (
    <div
      className={`bg-background border-l border-muted px-2 text-sm text-foreground ${leftCellBorder}`}
      style={{
        gridColumn: "1 / span 1",
        gridRow: `${gridRow} / span ${laneCount}`,
      }}
    >
      <div
        className="flex items-center"
        style={{ height: `${SLOT_HEIGHT_IN_REM}rem` }}
      >
        {children}
      </div>
    </div>
  );
}
