import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createChannelsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import type { ChannelSchemaValues } from "@/components/channels/channel-schema";
import { createChannelSchema } from "@/components/channels/channel-schema";
import {
  FormCheckbox,
  FormContainer,
  FormDuration,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function AddChannelForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const create = useMutation({
    ...createChannelsMutation(),
  });

  const schema = createChannelSchema(t);
  const form = useZodForm({ schema });

  const handleSubmit = (values: ChannelSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          active: values.active === true,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", { item: t("channels.channel") }),
          );
          navigate({ to: "/channels" });
        },
        onError: () =>
          toast.error(
            t("common.add.create_error", { item: t("channels.channel") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={create}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer title={t("channels.add.title")} buttons={buttons}>
      <Card>
        <CardHeader>
          <CardTitle>{t("channels.channel")}</CardTitle>
          <CardDescription>{t("channels.add.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="url" schema={schema} />
            <FormCheckbox name="active" schema={schema} />
            <FormDuration name="bookingRange" schema={schema} />
            <FormDuration name="prepareTime" schema={schema} />
          </FormContainer>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
