import type React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useProfile } from "@/hooks/use-profile";

export const LanguageProvider = ({ children }: React.PropsWithChildren) => {
  const { data } = useProfile();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data?.language) {
      i18n.changeLanguage(data.language.toLowerCase());
    }
  }, [data?.language, i18n]);

  return children;
};
