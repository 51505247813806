import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import type {
  ReservationResponseDTO,
  ResourceResponseDTO,
} from "@/client/private";
import {
  listReservationsOptions,
  listResourcesOptions,
} from "@/client/private/@tanstack/react-query.gen";
import type {
  PlannerReservation,
  PlannerResource,
} from "@/components/planner/planner.model";

export function useResourceReservationData(
  start: Date,
  end: Date,
  resourceIdsToShow: string[],
) {
  const reservationsRef = useRef<ReservationResponseDTO[]>([]);

  const defaultPagination = {
    page: 0,
    size: 1000,
  };

  const { data: resourceData, isLoading: isResourcesLoading } = useQuery({
    ...listResourcesOptions({
      body: {
        pagination: defaultPagination,
        sort: { sortBy: "name", direction: "ASC" },
        id: resourceIdsToShow.length ? resourceIdsToShow : undefined,
      },
    }),
  });

  const { data: reservationData, isLoading: isReservationsLoading } = useQuery({
    ...listReservationsOptions({
      body: {
        pagination: defaultPagination,
        startAt: [
          {
            operator: "LESS_THAN",
            value: end,
          },
        ],
        until: [
          {
            operator: "GREATER_THAN",
            value: start,
          },
        ],
        sort: { sortBy: "startAt", direction: "ASC" },
      },
    }),
  });

  // Use a ref to store previous reservations to avoid clearing the grid when
  // the reservations are being fetched after the initial fetch. Clearing the
  // grid when navigating in the grid causes unnecessary jumping of rows while
  // the new data is being fetched.
  if (reservationData?.content) {
    reservationsRef.current = reservationData.content;
  }

  const resources = mapResources(resourceData?.content);
  const reservations = mapReservations(reservationsRef.current);

  return {
    resources,
    reservations,
    isResourcesLoading,
    isReservationsLoading,
  };
}

function mapResources(
  resources: ResourceResponseDTO[] | undefined,
): PlannerResource[] {
  return resources ?? [];
}

function mapReservations(
  reservations: ReservationResponseDTO[] | undefined,
): PlannerReservation[] {
  return (reservations ?? [])
    .flatMap((reservation): PlannerReservation[] => {
      return reservation.line.map((line) => {
        const {
          name: bookableName,
          capacity,
          capacityLabel,
          resourceId,
        } = line;
        return {
          key: `$${resourceId}-${reservation.id}`, // TODO: use reservation line id instead of reservation id
          ...reservation,
          bookableName,
          capacity,
          capacityLabel,
          resourceId,
        };
      });
    })
    .sort((a, b) => a.startAt.getTime() - b.startAt.getTime());
}
