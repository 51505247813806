import { useQuery } from "@tanstack/react-query";
import { listChannelsOptions } from "@/client/private/@tanstack/react-query.gen";

export function useChannelData() {
  const { data, isPending } = useQuery({
    ...listChannelsOptions({
      body: {
        sort: {
          direction: "ASC",
          sortBy: "name",
        },
      },
    }),
  });

  const channelOptions =
    data?.content.map(({ id, url }) => ({ title: url, value: id })) ?? [];

  return { channelOptions, isChannelsPending: isPending };
}
