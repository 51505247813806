import { useFormContext } from "react-hook-form";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import { getFormFieldName, getSchemaProperties } from "@/components/form/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";

type FormTextAreaProps<T extends ZodShape> =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & FormFieldProps<T>;

export function FormTextArea<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  ...props
}: FormTextAreaProps<T>) {
  const form = useFormContext();

  const { label, isRequired } = getSchemaProperties(schema, name);

  return (
    <FormField
      name={getFormFieldName(path, name)}
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <FormItemLabel label={label} isRequired={isRequired} />
          <FormControl>
            <Textarea
              name={field.name}
              disabled={field.disabled}
              value={field.value ?? ""}
              ref={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
              {...props}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
