import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { OpeningHourResponseDTO } from "@/client/private";
import { DataTableDateCell } from "@/components/data-table/cells/data-table-date-cell";
import { DataTableRecurrenceCell } from "@/components/data-table/cells/data-table-recurrence-cell";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";
import { OpeningHourTableActionCell } from "@/components/opening-hours/list/OpeningHourTableActionCell";
import { OpeningHourTableOrderCell } from "@/components/opening-hours/list/OpeningHourTableOrderCell";

export function getOpeningHourTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<OpeningHourResponseDTO>> {
  return [
    {
      accessorKey: "startAt",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("opening_hours.properties.start")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateCell<OpeningHourResponseDTO>
          row={row}
          name="startAt"
          format="PP"
        />
      ),
    },
    {
      accessorKey: "until",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("opening_hours.properties.until")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateCell<OpeningHourResponseDTO>
          row={row}
          name="until"
          format="PP"
        />
      ),
    },
    {
      accessorKey: "operation",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("opening_hours.properties.operation")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<OpeningHourResponseDTO> row={row} name="operation" />
      ),
      accessorFn: (row) =>
        t(`opening_hours.operation.${row.operation.toLowerCase()}`),
      filterFn: (row, id, value) =>
        Array.isArray(value) && value.includes(row.getValue(id)),
    },
    {
      accessorKey: "recurringRule",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("opening_hours.properties.recurring_rule")}
        />
      ),
      cell: ({ row }) => (
        <DataTableRecurrenceCell<OpeningHourResponseDTO>
          row={row}
          ruleProperty="recurringRule"
          durationProperty="recurringDuration"
        />
      ),
    },
    {
      accessorKey: "order",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("opening_hours.properties.order")}
        />
      ),
      cell: ({ row, table }) => (
        <OpeningHourTableOrderCell
          row={row}
          onDataInvalidated={onDataInvalidated}
          buttonConfig={{
            showUp: table.getRowModel().rows.at(0) !== row,
            showDown: table.getRowModel().rows.at(-1) !== row,
          }}
        />
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <OpeningHourTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
