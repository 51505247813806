import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type {
  PageUnavailabilityResponse,
  UnavailabilityListDTO,
  UnavailabilityResponseDTO,
} from "@/client/private";
import { listUnavailability, listView } from "@/client/private";
import { listUnavailabilityQueryKey } from "@/client/private/@tanstack/react-query.gen";
import { AddItemButton } from "@/components/common/AddItemButton";
import { ItemListHeader } from "@/components/common/ItemListHeader";
import { DataTableLayoutWrapper } from "@/components/data-table/data-table-layout.tsx";
import { getUnavailabilityTableColumns } from "@/components/unavailability/list/unavailability-table-columns";
import { getUnavailabilityTableFilters } from "@/components/unavailability/list/unavailability-table-filters";
import { useDataTableSettings } from "@/hooks/use-data-table-settings.ts";

export const UnavailabilityTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    content,
    totalPages,
    pageIndex,
    setPageIndex,
    listSettings,
    onListSettingsChange,
    totalElements,
    isFetching,
    refetch,
  } = useDataTableSettings<PageUnavailabilityResponse, UnavailabilityListDTO>(
    listUnavailability,
    listUnavailabilityQueryKey,
    listView.UNAVAILABILITY,
  );

  const filterFields = useMemo(() => getUnavailabilityTableFilters(), []);
  const columns = useMemo(
    () => getUnavailabilityTableColumns(t, refetch),
    [t, refetch],
  );

  const handleRowClick = (row: Row<UnavailabilityResponseDTO>) => {
    navigate({ to: `/unavailability/${row.id}` });
  };

  return (
    <DataTableLayoutWrapper
      above={
        <ItemListHeader
          title={t("unavailability.title")}
          description={t("unavailability.description")}
          totalElements={totalElements}
        />
      }
      toolbar={
        <AddItemButton
          onClick={() => navigate({ to: "/unavailability/add" })}
          item={t("unavailability.unavailability")}
        />
      }
      columns={columns}
      content={content}
      totalPages={totalPages}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      onRowClick={handleRowClick}
      filterFields={filterFields}
      listSettings={listSettings}
      onListSettingsChange={onListSettingsChange}
      isFetching={isFetching}
    />
  );
};
