import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { z } from "zod";
import { updateProfileMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { PasswordRequirements } from "@/components/auth/PasswordRequirements";
import {
  FormContainer,
  FormInput,
  FormSelect,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { createEditProfileSchema } from "@/components/settings/profile/profile-schema";
import { SkeletonCard } from "@/components/ui/SkeletonCard.tsx";
import { useProfile } from "@/hooks/use-profile";
import { toBackendLanguage, toUiLanguage } from "@/lib/language.ts";

function createFormSchema(t: TFunction) {
  return createEditProfileSchema(t).pick({
    firstName: true,
    lastName: true,
    email: true,
    language: true,
    passwords: true,
  });
}

export const ProfileSettingsForm = () => {
  const client = useQueryClient();
  const { data, isLoading, queryKey: profileQueryKey } = useProfile();
  const update = useMutation({ ...updateProfileMutation() });
  const { t, i18n } = useTranslation();

  const schema = createFormSchema(t);
  const form = useZodForm({
    schema,
    values: {
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
      email: data?.email ?? "",
      language: toUiLanguage(data?.language),
      passwords: { password: "", confirmation: "" },
    },
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    const { passwords } = values;
    const hasPassword = (passwords.password ?? "").trim().length > 0;
    const language = toBackendLanguage(values.language);

    update.mutate(
      {
        body: {
          firstName: values.firstName,
          lastName: values.lastName,
          language,
          password: hasPassword ? passwords.password : undefined,
        },
      },
      {
        onSuccess: () => {
          i18n.changeLanguage(language.toLowerCase());
          toast.success(
            t("common.edit_success", { item: t("settings.profile.profile") }),
          );
          client.invalidateQueries({ queryKey: profileQueryKey });
        },
        onError: () => {
          toast.error(
            t("common.edit_error", { item: t("settings.profile.profile") }),
          );
        },
      },
    );
  };

  if (isLoading) return <SkeletonCard />;

  return (
    <FormContainer form={form} onSubmit={handleSubmit}>
      <FormInput name="firstName" schema={schema} />
      <FormInput name="lastName" schema={schema} />
      <FormSelect name="language" schema={schema} />
      <FormInput name="email" schema={schema} readOnly={true} />
      <FormInput
        name="password"
        path={["passwords"]}
        type="password"
        schema={schema.shape.passwords}
      />
      <FormInput
        name="confirmation"
        path={["passwords"]}
        type="password"
        schema={schema.shape.passwords}
      />
      <PasswordRequirements allowEmptyPassword={true} />
      <FormSubmit form={form} mutation={update}>
        {t("common.save")}
      </FormSubmit>
    </FormContainer>
  );
};
