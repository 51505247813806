import type { UseBaseMutationResult } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import type { FieldValues } from "react-hook-form";
import type { ZodForm } from "@/components/form/types";
import { Button } from "@/components/ui/button";

type FormSubmitProps<T extends FieldValues> = PropsWithChildren<{
  form: ZodForm<T>;
  mutation: Pick<UseBaseMutationResult, "isPending">;
  disabled?: boolean;
}>;

export function FormSubmit<T extends FieldValues>({
  form,
  mutation,
  disabled,
  children,
}: FormSubmitProps<T>) {
  return (
    <Button
      disabled={mutation.isPending || !form.formState.isValid || disabled}
      type="submit"
      form={form.id}
    >
      {children}
    </Button>
  );
}
