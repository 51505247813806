import type {
  ColumnFilter,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { direction, type ListSettingDTO } from "@/client/private";

export const toSearchTermState = (settings: ListSettingDTO) =>
  settings.searchTerm ?? "";

export const toSearchTermSetting = (
  searchTerm: string,
): Pick<ListSettingDTO, "searchTerm"> => {
  return (searchTerm ?? "").trim().length > 0
    ? { searchTerm }
    : { searchTerm: undefined };
};

export const toFiltersState = (settings: ListSettingDTO): Array<ColumnFilter> =>
  Object.entries(settings.filters).map(([key, value]) => ({
    id: key,
    value: value,
  }));

export const toFiltersSetting = (
  columnState: Array<ColumnFilter>,
): Pick<ListSettingDTO, "filters"> => ({
  filters: columnState.reduce((acc, state) => {
    return {
      ...acc,
      [state.id]: state.value,
    };
  }, {}),
});

export const toSortingState = (settings: ListSettingDTO): SortingState => [
  {
    id: settings.sort.sortBy,
    desc: settings.sort.direction === direction.DESC,
  },
];

export const toSortingSetting = (
  sortingState: SortingState,
): Pick<ListSettingDTO, "sort"> => ({
  sort: {
    sortBy: sortingState.map((col) => col.id).at(0) || "id",
    direction: sortingState.map((col) => col.desc).at(0)
      ? direction.DESC
      : direction.ASC,
  },
});

export const toPaginationState = (
  settings: ListSettingDTO,
  index: number,
): PaginationState => {
  return {
    pageIndex: index,
    pageSize: settings.pageSize,
  };
};

export const toPaginationSetting = (
  pagingState: PaginationState,
): Pick<ListSettingDTO, "pageSize"> => ({
  pageSize: pagingState.pageSize,
});
