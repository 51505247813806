import { addDays, format } from "date-fns";
import { frequencyHasWeekDays } from "@/components/recurrence/functions/frequency-has-weekdays";
import { getTimeComponents } from "@/components/recurrence/functions/time";
import type { RecurrenceFormData } from "@/components/recurrence/models/recurrence-form-data.model";

export function createRecurringRule(formValue: RecurrenceFormData) {
  const freq = `FREQ=${formValue.frequency}`;
  const { hour, minute } = getTimeComponents(formValue.startTime);
  const byHour = `BYHOUR=${hour}`;
  const byMinute = `BYMINUTE=${minute}`;
  const weekStart = `WKST=MO`;
  const byDay =
    frequencyHasWeekDays(formValue.frequency) && formValue.weekDays.length > 0
      ? `BYDAY=${formValue.weekDays.join(",")}`
      : "";
  const interval = `INTERVAL=${formValue.interval}`;

  const endDate = formValue.endDate
    ? formValue.endTime === "24:00"
      ? addDays(formValue.endDate, 1)
      : formValue.endDate
    : undefined;
  const endTime = formValue.endTime === "24:00" ? "00:00" : formValue.endTime;
  const until = endDate
    ? `UNTIL=${format(endDate, "yyyyMMdd")}T${endTime.replace(":", "")}00Z`
    : "";

  const parts = [
    freq,
    byHour,
    byMinute,
    weekStart,
    byDay,
    interval,
    until,
  ].filter((part) => part.length > 0);

  return parts.join(";");
}
