import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ResourceDetailResponseDTO } from "@/client/private";
import { updateResourcesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import {
  FormCheckboxGroup,
  FormContainer,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { useBookableData } from "@/components/resources/list/use-bookable-data";
import type { ResourceSchemaValues } from "@/components/resources/resource-schema";
import { createResourceSchema } from "@/components/resources/resource-schema";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditResourceProps {
  resource: ResourceDetailResponseDTO;
}

export function EditResourceForm({ resource }: EditResourceProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateResourcesMutation() });
  const { bookableOptions, isBookablesPending } = useBookableData();

  const schema = createResourceSchema(t);
  const form = useZodForm({ schema, values: resource });

  const handleSubmit = (values: ResourceSchemaValues) => {
    update.mutate(
      {
        path: { id: resource.id },
        body: {
          ...values,
          bookableId: values.bookableId ?? [],
          unavailabilityId: [],
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("resources.resource") }),
          );
          navigate({
            to: "/resources",
          });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("resources.resource") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={update}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer
      title={`${t("resources.resource")} ${resource.name}`}
      backTo={{
        to: "/resources",
      }}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("resources.edit.title")}</CardTitle>
          <CardDescription>{t("resources.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="name" schema={schema} />
            <FormInput name="capacity" schema={schema} />
            <FormInput name="shareLimit" schema={schema} />
            <FormCheckboxGroup
              name="bookableId"
              schema={schema}
              options={bookableOptions}
              isPending={isBookablesPending}
              description={
                bookableOptions.length === 0 && t("resources.add.no_bookables")
              }
            />
          </FormContainer>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
