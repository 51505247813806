// import { useMutation } from "@tanstack/react-query";
// import { useNavigate } from "@tanstack/react-router";
// import { useTranslation } from "react-i18next";
// import { toast } from "sonner";
import type { ReservationResponseDTO } from "@/client/private";

// import { updateReservationsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
// import {
//   FormContainer,
//   FormDateTime,
//   FormInput,
//   FormSubmit,
//   useZodForm,
// } from "@/components/form";
// import { EditItemContainer } from "@/components/layout/EditItemContainer";
// import { ReservationPlanner } from "@/components/reservations/ReservationPlanner";
// import type { ReservationSchemaValues } from "@/components/reservations/reservation-schema";
// import { createReservationSchema } from "@/components/reservations/reservation-schema";
// import { ResourceCapacitySelection } from "@/components/reservations/resource-capacity/ResourceCapacitySelection";
// import { getReservedCapacity } from "@/components/reservations/resource-capacity/get-reserved-capacity";
// import {
//   Card,
//   CardContent,
//   CardDescription,
//   CardHeader,
//   CardTitle,
// } from "@/components/ui/card";

interface EditReservationFormProps {
  reservation: ReservationResponseDTO;
}

export function EditReservationForm({ reservation }: EditReservationFormProps) {
  return <h1>Edit reservation {reservation.name}</h1>;

  // const { t } = useTranslation();
  // const navigate = useNavigate();
  // const update = useMutation({ ...updateReservationsMutation() });

  // const schema = createReservationSchema(t);
  // const form = useZodForm({
  //   schema,
  //   values: {
  //     window: {
  //       startAt: reservation.startAt,
  //       until: reservation.until,
  //     },
  //     clientName: reservation.name,
  //     clientEmail: reservation.email,
  //     clientPhoneNumber: reservation.phoneNumber,
  //     line: reservation.line,
  //   },
  // });
  // const startAt = form.watch("window.startAt");
  // const reservationLines = form.watch("line");

  // const handleSubmit = (values: ReservationSchemaValues) => {
  //   update.mutate(
  //     {
  //       path: { id: reservation.id },
  //       body: {
  //         startAt: values.window.startAt,
  //         until: values.window.until,
  //         name: values.clientName,
  //         email: values.clientEmail,
  //         phoneNumber: values.clientPhoneNumber ?? undefined,
  //         line: values.line,
  //       },
  //     },
  //     {
  //       onSuccess: async () => {
  //         toast.success(
  //           t("common.edit_success", { item: t("reservations.reservation") }),
  //         );
  //         navigate({ to: "/reservations" });
  //       },
  //       onError: () =>
  //         toast.error(
  //           t("common.edit_error", { item: t("reservations.reservation") }),
  //         ),
  //     },
  //   );
  // };

  // const reservedCapacity = getReservedCapacity(reservationLines);
  // const buttons = (
  //   <FormSubmit form={form} mutation={update} disabled={reservedCapacity === 0}>
  //     {t("common.save")}
  //   </FormSubmit>
  // );

  // return (
  //   <EditItemContainer
  //     title={`${t("reservations.reservation")} ${reservation.name}`}
  //     buttons={buttons}
  //   >
  //     <Card>
  //       <CardHeader>
  //         <CardTitle>{t("reservations.edit.title")}</CardTitle>
  //         <CardDescription>
  //           {t("reservations.edit.description")}
  //         </CardDescription>
  //       </CardHeader>
  //       <CardContent className="space-y-5">
  //         <FormContainer form={form} onSubmit={handleSubmit}>
  //           <FormDateTime
  //             name="startAt"
  //             path={["window"]}
  //             schema={schema.shape.window}
  //           />
  //           <FormDateTime
  //             name="until"
  //             path={["window"]}
  //             schema={schema.shape.window}
  //           />
  //           <FormInput name="clientName" schema={schema} />
  //           <FormInput name="clientEmail" schema={schema} />
  //           <FormInput name="clientPhoneNumber" schema={schema} />
  //         </FormContainer>
  //       </CardContent>
  //     </Card>
  //     <ResourceCapacitySelection
  //       reservationLines={reservationLines}
  //       onChange={(value) => form.setValue("line", value)}
  //     />
  //     <ReservationPlanner
  //       startAt={startAt}
  //       reservationLines={reservationLines}
  //     />
  //   </EditItemContainer>
  // );
}
