import { useQuery } from "@tanstack/react-query";
import { getRouteApi } from "@tanstack/react-router";
import { getChannelsOptions } from "@/client/private/@tanstack/react-query.gen.ts";
import { EditChannelForm } from "@/components/channels/edit/EditChannelForm";
import { FormSkeleton } from "@/components/form";

export const Edit = () => {
  const route = getRouteApi("/_auth/channels/$id");
  const { id } = route.useParams();
  const { data, isLoading } = useQuery({
    ...getChannelsOptions({ path: { id } }),
  });

  if (isLoading || !data) {
    return <FormSkeleton />;
  }

  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-4 lg:grid-cols-1 xl:grid-cols-1">
      <EditChannelForm channel={data} />
    </main>
  );
};
