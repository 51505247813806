import type { ColumnDef } from "@tanstack/react-table";
import type { TFunction } from "i18next";
import type { CombinedReservationResponseDTO } from "@/client/private";
import { DataTableDateTimeCell } from "@/components/data-table/cells/data-table-date-time-cell";
import { DataTableTextCell } from "@/components/data-table/cells/data-table-text-cell.tsx";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header.tsx";
import { ReservationTableActionCell } from "@/components/reservations/list/ReservationTableActionCell";

export function getReservationTableColumns(
  t: TFunction,
  onDataInvalidated: () => void,
): Array<ColumnDef<CombinedReservationResponseDTO>> {
  return [
    {
      accessorKey: "startAt",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.start_at")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateTimeCell<CombinedReservationResponseDTO>
          row={row}
          name="startAt"
        />
      ),
    },
    {
      accessorKey: "until",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.until")}
        />
      ),
      cell: ({ row }) => (
        <DataTableDateTimeCell<CombinedReservationResponseDTO>
          row={row}
          name="until"
        />
      ),
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.client")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<CombinedReservationResponseDTO>
          row={row}
          name="name"
        />
      ),
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.email")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<CombinedReservationResponseDTO>
          row={row}
          name="email"
        />
      ),
    },
    {
      accessorKey: "type",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("reservations.properties.type")}
        />
      ),
      cell: ({ row }) => (
        <DataTableTextCell<CombinedReservationResponseDTO>
          row={row}
          name="type"
        />
      ),
      accessorFn: (row) => t(`reservations.type.${row.type.toLowerCase()}`),
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <ReservationTableActionCell
          row={row}
          onDataInvalidated={onDataInvalidated}
        />
      ),
    },
  ];
}
