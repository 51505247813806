import { useQuery } from "@tanstack/react-query";
import {
  getProfileOptions,
  getProfileQueryKey,
} from "@/client/private/@tanstack/react-query.gen.ts";
import { HALF_HOUR } from "@/lib/constants";
import { useAuthStore } from "@/store/auth/use-auth-store.ts";

export const useProfile = () => {
  const queryKey = getProfileQueryKey();
  const sub = useAuthStore((state) => state.identity?.sub);

  const { data, isLoading } = useQuery({
    ...getProfileOptions(),
    queryKey,
    enabled: !!sub,
    staleTime: HALF_HOUR,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};
