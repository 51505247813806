import type { TFunction } from "i18next";
import type { CombinedReservationResponseDTO } from "@/client/private";
import { ReservationType } from "@/components/reservations/reservation-type.model";
import {
  searchTermColumnId,
  type DataTableFilterField,
} from "@/types/table.ts";

export function getReservationTableFilters(
  t: TFunction,
): Array<DataTableFilterField<CombinedReservationResponseDTO>> {
  return [
    {
      value: searchTermColumnId,
      placeholder: t("reservations.search_placeholder"),
    },
    {
      label: t("reservations.properties.type"),
      value: "type",
      options: Object.values(ReservationType).map((type) => ({
        label: t(`reservations.type.${type.toLocaleLowerCase()}`),
        value: type,
      })),
    },
  ];
}
