import type { TFunction } from "i18next";
import { z } from "zod";
import { language } from "@/client/private";
import { Languages, toUiLanguage } from "@/lib/language";

export const minPasswordLength = 8;
export const passwordSpecialChars = "!@#$%^&*";

export function createPasswordSchema(t: TFunction, passwordRequired: boolean) {
  const regexCheck = (regex: RegExp) => {
    return (password: string) => {
      const length = password?.length ?? 0;
      return length === 0 || regex.test(password);
    };
  };

  return z
    .string({
      required_error: t("common.field_required", {
        field: t("settings.profile.properties.password"),
      }),
    })
    .describe(t("settings.profile.properties.password"))
    .refine(
      (password) => {
        const length = password?.length ?? 0;
        return (
          (passwordRequired && length >= minPasswordLength) ||
          (!passwordRequired && (length === 0 || length >= minPasswordLength))
        );
      },
      {
        message: t("settings.profile.password_min_length", {
          min: minPasswordLength,
        }),
      },
    )
    .refine(regexCheck(/[a-z]/), {
      message: t("settings.profile.password_requirements.no_lower"),
    })
    .refine(regexCheck(/[A-Z]/), {
      message: t("settings.profile.password_requirements.no_upper"),
    })
    .refine(regexCheck(/[0-9]/), {
      message: t("settings.profile.password_requirements.no_number"),
    })
    .refine(regexCheck(new RegExp(`[${passwordSpecialChars}]`)), {
      message: t("settings.profile.password_requirements.no_special", {
        specialCharacters: passwordSpecialChars,
        interpolation: { escapeValue: false },
      }),
    });
}

function createPasswordConfirmationSchema(t: TFunction) {
  return z
    .string({
      required_error: t("common.field_required", {
        field: t("settings.profile.properties.password_confirmation"),
      }),
    })
    .describe(t("settings.profile.properties.password_confirmation"));
}

function createProfileBaseSchema(t: TFunction, defaultLanguage: language) {
  return z.object({
    firstName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.properties.first_name"),
        }),
      })
      .min(3, {
        message: t("settings.profile.first_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.profile.first_name_max_length", { max: 160 }),
      })
      .describe(t("settings.profile.properties.first_name")),
    lastName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.properties.last_name"),
        }),
      })
      .min(3, {
        message: t("settings.profile.last_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.profile.last_name_max_length", { max: 160 }),
      })
      .describe(t("settings.profile.properties.last_name")),
    isAdmin: z
      .boolean()
      .default(false)
      .optional()
      .describe(t("settings.profile.role.admin")),
    language: z
      .enum(Object.values(Languages) as [string, ...string[]], {
        required_error: t("common.field_required", {
          field: t("settings.profile.properties.language"),
        }),
      })
      .default(toUiLanguage(defaultLanguage))
      .describe(t("settings.profile.properties.language")),
    email: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.profile.properties.email"),
        }),
      })
      .email({ message: t("settings.profile.email_invalid") })
      .describe(t("settings.profile.properties.email")),
  });
}

function checkPasswordConfirmation({
  password,
  confirmation,
}: {
  password?: string | undefined;
  confirmation?: string | undefined;
}) {
  return (password ?? "") === (confirmation ?? "");
}

function createPasswordConfirmationError(t: TFunction) {
  return {
    message: t("settings.profile.passwords_must_match"),
    path: ["confirmation"],
  };
}

export function createAddProfileSchema(
  t: TFunction,
  defaultLanguage: language = language.EN,
) {
  return createProfileBaseSchema(t, defaultLanguage).merge(
    z.object({
      passwords: z
        .object({
          password: createPasswordSchema(t, true),
          confirmation: createPasswordConfirmationSchema(t),
        })
        .refine(checkPasswordConfirmation, createPasswordConfirmationError(t)),
    }),
  );
}

export function createEditProfileSchema(
  t: TFunction,
  defaultLanguage: language = language.EN,
) {
  return createProfileBaseSchema(t, defaultLanguage).merge(
    z.object({
      passwords: z
        .object({
          password: createPasswordSchema(t, false).optional(),
          confirmation: createPasswordConfirmationSchema(t).optional(),
        })
        .refine(checkPasswordConfirmation, createPasswordConfirmationError(t)),
    }),
  );
}

export type AddProfileSchemaValues = z.infer<
  ReturnType<typeof createAddProfileSchema>
>;

export type EditProfileSchemaValues = z.infer<
  ReturnType<typeof createEditProfileSchema>
>;
