import type { TFunction } from "i18next";
import { z } from "zod";
import { resourceUsageType } from "@/client/private";

export function createBookableSchema(t: TFunction) {
  return z.object({
    name: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("bookables.properties.name"),
        }),
      })
      .describe(t("bookables.properties.name")),
    description: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.description"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("bookables.properties.description"),
        }),
      })
      .describe(t("bookables.properties.description")),
    capacityLabel: z
      .string()
      .nullish()
      .describe(t("bookables.properties.capacity_label")),
    duration: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.duration"),
        }),
      })
      .duration({ message: t("bookables.add.duration_invalid") })
      .describe(t("bookables.properties.duration")),
    slotInterval: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.slot_interval"),
        }),
      })
      .duration({ message: t("bookables.add.slot_interval_invalid") })
      .describe(t("bookables.properties.slot_interval")),
    resourceUsageType: z
      .enum([
        resourceUsageType.TOTAL_DURATION,
        resourceUsageType.SLOT_INTERVAL,
        resourceUsageType.UNTIL_OPERATION_CLOSED,
        resourceUsageType.UNTIL_USER_DEFINED,
      ])
      .default(resourceUsageType.TOTAL_DURATION)
      .describe(t("bookables.properties.resource_usage_type")),
    channelId: z
      .array(z.string())
      .default([])
      .optional()
      .describe(t("bookables.properties.channels")),
    resourceId: z
      .array(z.string())
      .default([])
      .optional()
      .describe(t("bookables.properties.resources")),
  });
}

export type BookableSchemaValues = z.infer<
  ReturnType<typeof createBookableSchema>
>;
