import type { PropsWithChildren } from "react";
import { cn } from "@/lib/utils";

export function FormlessForm({
  children,
  className,
  ...props
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={cn("flex flex-wrap gap-5", className)} {...props}>
      {children}
    </div>
  );
}

export function FormlessField({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div className={cn("flex flex-col gap-2 grow md:grow-0", className)}>
      {children}
    </div>
  );
}
