import type { PropsWithChildren } from "react";
import type { GridLayout } from "@/components/planner/grid-layout";
import { cn } from "@/lib/utils";

type TimeSlotCellProps = PropsWithChildren<{
  resourceId: string;
  timeSlot: Date;
  gridLayout: GridLayout;
}>;

export function TimeSlotCell({
  resourceId,
  timeSlot,
  gridLayout,
  children,
}: TimeSlotCellProps) {
  const isBottomRightCell =
    gridLayout.isLastResource(resourceId) &&
    gridLayout.isLastTimeSlot(timeSlot);
  const rightCellBorder = isBottomRightCell ? "rounded-br-md" : "";

  const laneCount = gridLayout.getLanes(resourceId);
  const gridColumn = gridLayout.getResourceColumn(timeSlot);
  const gridRow = gridLayout.getRow(resourceId);
  const rowSpan = laneCount;

  return (
    <div
      className={cn(
        `border h-full flex items-center justify-center`,
        rightCellBorder,
      )}
      style={{
        gridColumn: `${gridColumn} / span 1`,
        gridRow: `${gridRow} / span ${rowSpan}`,
      }}
    >
      {children}
    </div>
  );
}
