import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { startOfDay } from "date-fns";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { UnavailabilityDetailResponseDTO } from "@/client/private";
import { updateUnavailabilityMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { RecurrenceForm } from "@/components/recurrence/RecurrenceForm";
import { createRecurringRule } from "@/components/recurrence/functions/create-recurring-rule";
import {
  getDurationFromStartAndEndTime,
  getEndTimeFromStartAndDuration,
} from "@/components/recurrence/functions/duration";
import { parseRecurringRule } from "@/components/recurrence/functions/parse-rule";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Form, FormField } from "@/components/ui/form";
import type { UnavailabilityFormData } from "@/components/unavailability/models/unavailability-form-data.model";
import { ResourceSelection } from "@/components/unavailability/resource-selection/ResourceSelection";

type EditUnavailabilityFormProps = {
  unavailability: UnavailabilityDetailResponseDTO;
};

export function EditUnavailabilityForm({
  unavailability,
}: EditUnavailabilityFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({
    ...updateUnavailabilityMutation(),
  });

  const rule = useMemo(
    () => parseRecurringRule(unavailability.recurringRule),
    [unavailability.recurringRule],
  );
  const endTime = useMemo(
    () =>
      getEndTimeFromStartAndDuration(
        rule.startTime,
        unavailability.recurringDuration,
      ),
    [rule.startTime, unavailability.recurringDuration],
  );

  const form = useForm<UnavailabilityFormData>({
    values: {
      startDate: unavailability.startAt ?? startOfDay(new Date()),
      endDate: rule.endDate,
      startTime: rule.startTime,
      endTime,
      frequency: rule.frequency,
      interval: rule.interval,
      weekDays: rule.weekDays,
      resourceIds: unavailability.resource?.map((r) => r.id) ?? [],
    },
    mode: "onChange",
  });

  const handleSubmit = (values: UnavailabilityFormData) => {
    const rule = createRecurringRule(values);

    update.mutate(
      {
        path: { id: unavailability.id },
        body: {
          ...values,
          startAt: values.startDate,
          recurringDuration: getDurationFromStartAndEndTime(
            values.startTime,
            values.endTime,
          ),
          recurringRule: rule,
          resourceId: values.resourceIds,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", {
              item: t("unavailability.unavailability"),
            }),
          );
          navigate({ to: "/unavailability" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", {
              item: t("unavailability.unavailability"),
            }),
          ),
      },
    );
  };

  const sidePanel = (
    <FormField
      control={form.control}
      name="resourceIds"
      render={({ field }) => (
        <ResourceSelection value={field.value} onChange={field.onChange} />
      )}
    ></FormField>
  );

  const buttons = (
    <Button
      type="submit"
      disabled={update.isPending || !form.formState.isValid}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <EditItemContainer
          title={t("unavailability.edit.title")}
          sidePanel={sidePanel}
          buttons={buttons}
        >
          <Card>
            <CardHeader>
              <CardTitle>{t("unavailability.unavailability")}</CardTitle>
              <CardDescription>
                {t("unavailability.edit.description")}
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-5">
              <RecurrenceForm />
            </CardContent>
          </Card>
        </EditItemContainer>
      </form>
    </Form>
  );
}
