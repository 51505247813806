import { useState } from "react";
import type { ReservationRequestResources } from "@/components/planner/ReservationRequest";
import { ReservationRequests } from "@/components/planner/ReservationRequests";
import ResourceReservationGrid from "@/components/planner/ResourceReservationGrid";
import type {
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner.model";
import { useBookableRequestData } from "@/components/planner/use-bookable-request-data";

export default function PlannerWithRequests() {
  const { bookables, requests } = useBookableRequestData();

  const [_, setRequestResources] = useState<
    Map<PlannerResource["id"], PlannerReservationRequest["id"][]>
  >(new Map());

  const handleReservationRequestResourceSelection = (
    selection: ReservationRequestResources,
  ) => {
    setRequestResources((prevRequestResources) => {
      const newRequestResources = new Map(prevRequestResources);
      newRequestResources.set(selection.requestId, selection.resourceIds);
      return newRequestResources;
    });
  };

  // TODO: this is just dummy data for now!
  // const assignedRequests = Array.from(requestResources.entries()).flatMap(
  //   ([requestId, resourceIds]) => {
  //     const request = requests.find(({ id }) => id === requestId);
  //     const requestResources = resources.filter(({ id }) =>
  //       resourceIds.includes(id),
  //     );

  //     if (request == null || requestResources.length === 0) {
  //       return [];
  //     }

  //     const bookable = bookables.find(({ id }) => id === request?.bookableId);

  //     if (bookable == null) {
  //       return [];
  //     }

  //     return requestResources.map((resource) => ({
  //       ...request,
  //       reservationStatus: "RESERVED" as reservationStatus2,
  //       resourceId: resource.id,
  //       bookableName: bookable.name,
  //     }));
  //   },
  // );

  return (
    <div className="grid grid-rows-1 xl:grid-cols-[1fr_auto] gap-2 px-4">
      <ResourceReservationGrid />
      <ReservationRequests
        bookables={bookables}
        requests={requests}
        handleSelectionChanged={handleReservationRequestResourceSelection}
      />
    </div>
  );
}
