import type { TFunction } from "i18next";
import type { EmployeeResponseDTO } from "@/client/private";
import {
  searchTermColumnId,
  type DataTableFilterField,
} from "@/types/table.ts";

export function getEmployeeTableFilters(
  t: TFunction,
): Array<DataTableFilterField<EmployeeResponseDTO>> {
  return [
    {
      value: searchTermColumnId,
      placeholder: t("employees.search_placeholder"),
    },
  ];
}
