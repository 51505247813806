import { Cross2Icon } from "@radix-ui/react-icons";
import type { Table } from "@tanstack/react-table";
import { Loader2 } from "lucide-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SearchInput } from "@/components/common/SearchInput";
import { DataTableFacetedFilter } from "@/components/data-table/data-table-faceted-filter";
import { DataTableViewOptions } from "@/components/data-table/data-table-view-options";
import { Button } from "@/components/ui/button";
import { useDebounce } from "@/hooks/use-debounce";
import { cn } from "@/lib/utils";
import type {
  DataTableFilterField,
  DataTableListFilterField,
  DataTableSearchTermFilterField,
} from "@/types/table";

interface DataTableToolbarProps<TData>
  extends React.HTMLAttributes<HTMLDivElement> {
  table: Table<TData>;
  isLoading?: boolean;
  filterFields?: Array<DataTableFilterField<TData>>;
}

export function DataTableToolbar<TData>({
  table,
  filterFields = [],
  children,
  className,
  isLoading,
  ...props
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = React.useState(
    table.getState().globalFilter ?? "",
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const isFiltered = table.getState().columnFilters.length > 0 || searchTerm;

  React.useEffect(() => {
    table.setGlobalFilter(debouncedSearchTerm);
  }, [table, debouncedSearchTerm]);

  // Memoize computation of searchableColumns and filterableColumns
  const { searchTermField, filterableColumns } = React.useMemo(() => {
    return {
      searchTermField: filterFields.find(
        (field): field is DataTableSearchTermFilterField =>
          "placeholder" in field,
      ),
      filterableColumns: filterFields.filter(
        (field): field is DataTableListFilterField<TData> => "options" in field,
      ),
    };
  }, [filterFields]);

  return (
    <div
      className={cn(
        "flex flex-1 flex-wrap gap-2 justify-between w-full overflow-auto p-1",
        className,
      )}
      {...props}
    >
      <div className="flex flex-1 flex-wrap gap-2">
        {searchTermField && (
          <SearchInput
            className="w-full min-w-40 md:w-[200px] lg:w-[336px]"
            placeholder={searchTermField.placeholder}
            maxLength={255}
            value={searchTerm}
            onChange={setSearchTerm}
          />
        )}
        {filterableColumns.length > 0 &&
          filterableColumns.map(
            (column) =>
              table.getColumn(column.value ? String(column.value) : "") && (
                <DataTableFacetedFilter
                  key={String(column.value)}
                  column={table.getColumn(
                    column.value ? String(column.value) : "",
                  )}
                  title={column.label}
                  options={column.options ?? []}
                />
              ),
          )}
        {isFiltered && (
          <Button
            aria-label={t("table.filters.reset_label")}
            variant="ghost"
            className="h-8 px-2 lg:px-3"
            onClick={() => {
              table.resetColumnFilters();
              setSearchTerm("");
            }}
          >
            {t("table.filters.reset")}
            <Cross2Icon className="ml-2 size-4" aria-hidden="true" />
          </Button>
        )}
        {isLoading && (
          <Button variant="ghost" className="h-8 px-2 lg:px-3">
            <Loader2 className="animate-spin" />
          </Button>
        )}
      </div>
      <div className="flex gap-2">
        {children}
        <DataTableViewOptions table={table} />
      </div>
    </div>
  );
}
