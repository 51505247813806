import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type {
  PageResourceResponse,
  ResourceListDTO,
  ResourceResponseDTO,
} from "@/client/private";
import { listResources, listView } from "@/client/private";
import { listResourcesQueryKey } from "@/client/private/@tanstack/react-query.gen";
import { AddItemButton } from "@/components/common/AddItemButton";
import { ItemListHeader } from "@/components/common/ItemListHeader";
import { DataTableLayoutWrapper } from "@/components/data-table/data-table-layout.tsx";
import { getResourceColumns } from "@/components/resources/list/resource-table-columns";
import { getResourceTableFilters } from "@/components/resources/list/resource-table-filters";
import { useDataTableSettings } from "@/hooks/use-data-table-settings.ts";

export const ResourceTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    content,
    totalPages,
    totalElements,
    pageIndex,
    setPageIndex,
    listSettings,
    onListSettingsChange,
    isFetching,
    refetch,
  } = useDataTableSettings<PageResourceResponse, ResourceListDTO>(
    listResources,
    listResourcesQueryKey,
    listView.RESOURCE,
  );

  const filterFields = useMemo(() => getResourceTableFilters(t), [t]);
  const columns = useMemo(() => getResourceColumns(t, refetch), [t, refetch]);

  const handleRowClick = (row: Row<ResourceResponseDTO>) => {
    navigate({ to: `/resources/${row.id}` });
  };

  return (
    <DataTableLayoutWrapper
      above={
        <ItemListHeader
          title={t("resources.title")}
          description={t("resources.description")}
          totalElements={totalElements}
        />
      }
      toolbar={
        <AddItemButton
          item={t("resources.resource")}
          onClick={() => navigate({ to: `/resources/add` })}
        />
      }
      columns={columns}
      content={content}
      totalPages={totalPages}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      onRowClick={handleRowClick}
      filterFields={filterFields}
      listSettings={listSettings}
      onListSettingsChange={onListSettingsChange}
      isFetching={isFetching}
    />
  );
};
