import { ResourceCell } from "@/components/planner/ResourceCell";
import { TimeSlotCell } from "@/components/planner/TimeSlotCell";
import type { GridLayout } from "@/components/planner/grid-layout";
import type { PlannerResource } from "@/components/planner/planner.model";

type ResourceRowProps = {
  resource: PlannerResource;
  gridLayout: GridLayout;
};

export function ResourceRow({ resource, gridLayout }: ResourceRowProps) {
  return (
    <>
      <ResourceCell resourceId={resource.id} gridLayout={gridLayout}>
        {resource.name}
      </ResourceCell>
      {gridLayout.timeSlots.map((timeSlot) => (
        <TimeSlotCell
          key={`${resource.id}-${timeSlot}`}
          resourceId={resource.id}
          timeSlot={timeSlot}
          gridLayout={gridLayout}
        />
      ))}
    </>
  );
}
