import { Check, ChevronsUpDown } from "lucide-react";
import type { ButtonHTMLAttributes } from "react";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

type Option = { id: string; name: string };

type OptionSelectProps<T extends Option> =
  ButtonHTMLAttributes<HTMLButtonElement> & {
    selectedId: string | undefined;
    options: T[] | undefined;
    isLoading: boolean;
    onOptionSelected: (option: T | undefined) => void;
    title: string;
    placeholder?: string;
    noItemsMessage?: string;
  };

export function OptionSelect<T extends Option>({
  selectedId,
  options,
  isLoading,
  title,
  onOptionSelected,
  ...props
}: OptionSelectProps<T>) {
  const [open, setOpen] = useState(false);

  if (isLoading || !options) {
    return <Skeleton className="h-10 w-72" />;
  }

  const selectedOption = options.find(({ id }) => id === selectedId);
  const { noItemsMessage, ...cleanedProps } = props;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="min-w-72" {...cleanedProps}>
          <div className="flex justify-between items-center grow">
            {selectedOption?.name ?? title}
            <ChevronsUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start">
        <OptionList
          {...props}
          options={options}
          value={selectedId}
          setOpen={setOpen}
          onOptionSelected={onOptionSelected}
        />
      </PopoverContent>
    </Popover>
  );
}

type OptionListProps<T extends Option> = {
  options: T[];
  value: string | undefined;
  setOpen: (open: boolean) => void;
  onOptionSelected: (option: T | undefined) => void;
  placeholder?: string;
  noItemsMessage?: string;
};

function OptionList<T extends Option>({
  options,
  value,
  setOpen,
  onOptionSelected,
  placeholder,
  noItemsMessage,
}: OptionListProps<T>) {
  const handleOptionSelected = (optionId: string) => {
    const option = options.find(({ id }) => id === optionId);
    onOptionSelected(option);
    setOpen(false);
  };

  const filterOptions = (
    _value: string,
    search: string,
    keywords?: string[] | undefined,
  ) => {
    return keywords?.[0]?.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
  };

  return (
    <Command filter={filterOptions}>
      <CommandInput placeholder={placeholder} />
      <CommandList>
        <CommandEmpty>{noItemsMessage}</CommandEmpty>
        <CommandGroup>
          {options.map((option) => (
            <CommandItem
              key={option.id}
              value={option.id}
              onSelect={handleOptionSelected}
              keywords={[option.name]}
            >
              {option.name}
              <Check
                className={cn(
                  "ml-auto size-4",
                  option.id === value ? "opacity-100" : "opacity-0",
                )}
              />
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
