import { jwtDecode } from "jwt-decode";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import type { AuthenticationResult } from "@/client/public";
import type { Identity } from "@/types/authentication";

export type UseAuthStore = {
  tokens?: AuthenticationResult;
  identity?: Identity;
};

export type Actions = {
  update: (tokens: AuthenticationResult) => void;
  clear: () => void;
};

export const useAuthStore = create<UseAuthStore & Actions>()(
  persist(
    (set) => ({
      tokens: undefined,
      update: (tokens): void => {
        if (tokens.idToken) {
          set({
            tokens: tokens,
            identity: jwtDecode<Identity>(tokens.idToken),
          });
        } else {
          set({ tokens: tokens, identity: undefined });
        }
      },
      clear: (): void => {
        set({ tokens: undefined, identity: undefined });
      },
    }),
    { name: "auth-store", skipHydration: false },
  ),
);
