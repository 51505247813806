import { createRouter } from "@tanstack/react-router";
import React from "react";
import ReactDOM from "react-dom/client";
import "@/lib/i18n.ts";
import "@/styles/globals.css";
import App from "./App.tsx";
import { routeTree } from "./routeTree.gen.ts";

const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
  // This infers the type of our router and registers it across your entire project
  interface Register {
    router: typeof router;
  }

  // Add strict static data types to the router
  interface StaticDataRouteOption {
    breadcrumb?: string;
  }
}

const rootElement = document.querySelector("#root") as Element;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App router={router} />
    </React.StrictMode>,
  );
}
