import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createEmployeeMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { PasswordRequirements } from "@/components/auth/PasswordRequirements";
import {
  FormCheckbox,
  FormContainer,
  FormInput,
  FormSelect,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import type { AddProfileSchemaValues } from "@/components/settings/profile/profile-schema";
import { createAddProfileSchema } from "@/components/settings/profile/profile-schema";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useProfile } from "@/hooks/use-profile";
import { toBackendLanguage } from "@/lib/language";

export function AddEmployeeForm() {
  const { t } = useTranslation();
  const profile = useProfile();
  const navigate = useNavigate();
  const create = useMutation({
    ...createEmployeeMutation(),
  });

  const schema = createAddProfileSchema(t, profile.data?.language);
  const form = useZodForm({ schema });

  const handleSubmit = (values: AddProfileSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          password: values.passwords.password,
          role: values.isAdmin ? ["ADMIN"] : [],
          language: toBackendLanguage(values.language),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", { item: t("employees.employee") }),
          );
          navigate({ to: "/employees" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("employees.employee") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={create}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer title={t("employees.add.title")} buttons={buttons}>
      <Card>
        <CardHeader>
          <CardTitle>{t("employees.employee")}</CardTitle>
          <CardDescription>{t("employees.add.description")}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="firstName" schema={schema} />
            <FormInput name="lastName" schema={schema} />
            <FormCheckbox
              name="isAdmin"
              schema={schema}
              description={t("settings.profile.role.admin_description")}
            />
            <FormSelect name="language" schema={schema} />
            <FormInput name="email" schema={schema} />
            <FormInput
              name="password"
              path={["passwords"]}
              type="password"
              schema={schema.shape.passwords}
            />
            <FormInput
              name="confirmation"
              path={["passwords"]}
              type="password"
              schema={schema.shape.passwords}
            />
          </FormContainer>
          <PasswordRequirements />
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
