import { useQuery } from "@tanstack/react-query";
import { listResourcesOptions } from "@/client/private/@tanstack/react-query.gen";

export function useResourceData() {
  const { data, isPending } = useQuery({
    ...listResourcesOptions({
      body: {
        sort: {
          direction: "ASC",
          sortBy: "name",
        },
      },
    }),
  });

  const resourceOptions =
    data?.content.map(({ id, name }) => ({ title: name, value: id })) ?? [];

  return { resourceOptions, isResourcePending: isPending };
}
