import { tz } from "@date-fns/tz";
import { format } from "date-fns";
import { enUS, nl } from "date-fns/locale";
import { useCompany } from "@/hooks/use-company";
import { useProfile } from "@/hooks/use-profile";

export const useDateFormat = () => {
  const { data: profile } = useProfile();
  const { data: company } = useCompany(profile?.companyId);

  const locale = profile?.language === "NL" ? nl : enUS;
  const profileZone = tz(company?.timezoneId ?? "Europe/Amsterdam");
  const utcZone = tz("UTC");

  return {
    formatDate: (date: string | number | Date, formatString: string) =>
      format(date, formatString, { locale, in: profileZone }),
    formatDateUtc: (date: string | number | Date, formatString: string) =>
      format(date, formatString, { locale, in: utcZone }),
    profileZone,
    utcZone,
    weekStartsOn: locale.options?.weekStartsOn ?? 0,
  };
};
