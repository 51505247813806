import { CheckIcon, XIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReservationDetails } from "@/components/planner/ReservationDetails";
import type {
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner.model";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";

export type ReservationRequestResources = {
  requestId: PlannerReservationRequest["id"];
  resourceIds: PlannerResource["id"][];
};

type ReservationRequestProps = {
  request: PlannerReservationRequest;
  availableResources: PlannerResource[];
  handleSelectionChanged: (selection: ReservationRequestResources) => void;
};

export function ReservationRequest({
  request,
  availableResources,
  handleSelectionChanged,
}: ReservationRequestProps) {
  const { t } = useTranslation();
  const [selectedResources, setSelectedResources] = useState(new Set<string>());

  const handleCheckedChange = (resourceId: string) => {
    return (checked: boolean) => {
      const newSet = new Set(selectedResources);
      checked ? newSet.add(resourceId) : newSet.delete(resourceId);

      setSelectedResources(newSet);
      handleSelectionChanged({
        requestId: request.id,
        resourceIds: [...newSet.values()],
      });
    };
  };

  const handleClear = () => {
    setSelectedResources(new Set());
    handleSelectionChanged({
      requestId: request.id,
      resourceIds: [],
    });
  };

  const handleAssignRequest = () => {
    console.log("Assign request", selectedResources);
  };

  return (
    <>
      <ReservationDetails reservation={request} />
      <Separator className="my-2" />
      <div className="flex flex-row gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button className="grow" variant="outline">
              {selectedResources.size
                ? t("reservationRequests.resourcesSelected", {
                    count: selectedResources.size,
                  })
                : t("reservationRequests.selectResources")}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            {availableResources.map((resource) => (
              <DropdownMenuCheckboxItem
                key={resource.id}
                checked={selectedResources.has(resource.id)}
                onCheckedChange={handleCheckedChange(resource.id)}
              >
                {resource.name}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        <Button size="icon" variant="outline" onClick={handleClear}>
          <XIcon className="h-4 w-4" />
        </Button>

        <Button size="icon" variant="outline" onClick={handleAssignRequest}>
          <CheckIcon className="h-4 w-4" />
        </Button>
      </div>
    </>
  );
}
