import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ReservationRequest,
  type ReservationRequestResources,
} from "@/components/planner/ReservationRequest";
import type {
  PlannerBookable,
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner.model";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

type ReservationRequestsProps = {
  bookables: PlannerBookable[];
  requests: PlannerReservationRequest[];
  handleSelectionChanged: (selection: ReservationRequestResources) => void;
};

export function ReservationRequests({
  bookables,
  requests,
  handleSelectionChanged,
}: ReservationRequestsProps) {
  const { t } = useTranslation();
  const [expandedRequests, setExpandedRequests] = useState<string[] | null>(
    null,
  );
  const defaultExpanded = bookables.map((bookable) => bookable.id).slice(0, 1);

  return (
    <Card className="xl:w-80 w-full">
      <CardHeader>{t("reservationRequests.title")}</CardHeader>
      <CardContent>
        <Accordion
          type="multiple"
          value={expandedRequests ?? defaultExpanded}
          onValueChange={setExpandedRequests}
        >
          {bookables.map((bookable) => {
            // TODO: Filter out resources that are already reserved
            const availableResources = [] as PlannerResource[]; //bookable.resources;
            const bookableRequests = requests.filter(
              (r) => r.bookableId === bookable.id,
            );

            return (
              <AccordionItem key={bookable.id} value={bookable.id}>
                <AccordionTrigger>
                  {bookable.name} ({bookableRequests.length})
                </AccordionTrigger>
                <AccordionContent>
                  {bookableRequests.map((request) => (
                    <div key={request.id} className="p-1">
                      <ReservationRequest
                        request={request}
                        availableResources={availableResources}
                        handleSelectionChanged={handleSelectionChanged}
                      />
                    </div>
                  ))}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </CardContent>
    </Card>
  );
}
