import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Link, getRouteApi, useNavigate } from "@tanstack/react-router";
import type { TFunction } from "i18next";
import { Loader2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import * as z from "zod";
import { passwordResetMutation } from "@/client/public/@tanstack/react-query.gen";
import { FormInput } from "@/components/form";
import { Button } from "@/components/ui/button.tsx";
import { Form } from "@/components/ui/form.tsx";

function createFormSchema(t: TFunction) {
  return z.object({
    email: z
      .string()
      .email({ message: t("login.invalid_email") })
      .describe(t("login.email")),
  });
}

type PasswordRecoveryFormValue = z.infer<ReturnType<typeof createFormSchema>>;

export function PasswordRecoveryForm() {
  const navigate = useNavigate();
  const mutation = useMutation({
    ...passwordResetMutation(),
  });
  const { t } = useTranslation();

  const route = getRouteApi("/password-recovery");
  const { email } = route.useSearch();

  const schema = createFormSchema(t);
  const form = useForm<PasswordRecoveryFormValue>({
    resolver: zodResolver(schema),
    defaultValues: { email },
  });

  const onSubmit = async (form: PasswordRecoveryFormValue) => {
    await mutation.mutateAsync(
      {
        body: {
          email: form.email,
        },
      },
      {
        onError: (error) => {
          toast.error(
            error.response?.data.message ||
              t("password_recovery.recovery_error"),
            {
              position: "top-center",
              important: true,
            },
          );
        },
        onSuccess: () => {
          toast.success(t("password_recovery.email_sent"), {
            position: "top-center",
            important: true,
          });
          navigate({ to: "/login" });
        },
      },
    );
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2 w-full">
        <FormInput
          name="email"
          schema={schema}
          placeholder={t("login.email_placeholder")}
          disabled={mutation.isPending}
          tabIndex={1}
        />
        <div className="w-full text-right pb-4">
          <Link
            to={`/login`}
            className="text-sm text-muted-foreground underline"
            tabIndex={4}
          >
            {t("password_recovery.remembered_password")}
          </Link>
        </div>
        <Button
          disabled={mutation.isPending}
          className="ml-auto w-full"
          type="submit"
          onClick={form.handleSubmit(onSubmit)}
          tabIndex={3}
        >
          {mutation.isPending && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          {t("password_recovery.request_link")}
        </Button>
      </form>
    </Form>
  );
}
