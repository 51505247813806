// import { useMutation } from "@tanstack/react-query";
// import { useNavigate } from "@tanstack/react-router";
// import { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { toast } from "sonner";
// import type { ReservationLineResponseDTO } from "@/client/private";
// import { createReservationsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
// import {
//   FormContainer,
//   FormDateTime,
//   FormInput,
//   FormSubmit,
//   useZodForm,
// } from "@/components/form";
// import { EditItemContainer } from "@/components/layout/EditItemContainer";
// import { ReservationPlanner } from "@/components/reservations/ReservationPlanner";
// import type { ReservationSchemaValues } from "@/components/reservations/reservation-schema";
// import { createReservationSchema } from "@/components/reservations/reservation-schema";
// import { ResourceCapacitySelection } from "@/components/reservations/resource-capacity/ResourceCapacitySelection";
// import { getReservedCapacity } from "@/components/reservations/resource-capacity/get-reserved-capacity";
// import {
//   Card,
//   CardContent,
//   CardDescription,
//   CardHeader,
//   CardTitle,
// } from "@/components/ui/card";

export function AddReservationForm() {
  return <h1>Add reservation</h1>;
  //   const { t } = useTranslation();
  //   const navigate = useNavigate();
  //   const create = useMutation({
  //     ...createReservationsMutation(),
  //   });

  //   const schema = createReservationSchema(t);
  //   const form = useZodForm({ schema });
  //   const startAt = form.watch("window.startAt");

  //   const [reservationLines, setReservationLines] = useState<
  //     ReservationLineResponseDTO[]
  //   >([]);

  //   const handleSubmit = (values: ReservationSchemaValues) => {
  //     create.mutate(
  //       {
  //         body: {
  //           startAt: values.window.startAt,
  //           until: values.window.until,
  //           name: values.clientName,
  //           email: values.clientEmail ?? undefined,
  //           phoneNumber: values.clientPhoneNumber ?? undefined,
  //           reservationLine: reservationLines,
  //         },
  //       },
  //       {
  //         onSuccess: () => {
  //           toast.success(
  //             t("common.create_success", { item: t("reservations.reservation") }),
  //           );
  //           navigate({ to: "/reservations" });
  //         },
  //         onError: () =>
  //           toast.error(
  //             t("common.create_error", { item: t("reservations.reservation") }),
  //           ),
  //       },
  //     );
  //   };

  //   const reservedCapacity = getReservedCapacity(reservationLines);
  //   const buttons = (
  //     <FormSubmit form={form} mutation={create} disabled={reservedCapacity === 0}>
  //       {t("common.save")}
  //     </FormSubmit>
  //   );

  //   return (
  //     <EditItemContainer title={t("reservations.add.title")} buttons={buttons}>
  //       <Card>
  //         <CardHeader>
  //           <CardTitle>{t("reservations.reservation")}</CardTitle>
  //           <CardDescription>{t("reservations.add.description")}</CardDescription>
  //         </CardHeader>
  //         <CardContent className="space-y-5">
  //           <FormContainer form={form} onSubmit={handleSubmit}>
  //             <FormDateTime
  //               name="startAt"
  //               path={["window"]}
  //               schema={schema.shape.window}
  //             />
  //             <FormDateTime
  //               name="until"
  //               path={["window"]}
  //               schema={schema.shape.window}
  //             />
  //             <FormInput name="clientName" schema={schema} />
  //             <FormInput name="clientEmail" schema={schema} />
  //             <FormInput name="clientPhoneNumber" schema={schema} />
  //           </FormContainer>
  //         </CardContent>
  //       </Card>
  //       <ResourceCapacitySelection
  //         reservationLines={reservationLines}
  //         onChange={setReservationLines}
  //       />
  //       <ReservationPlanner
  //         startAt={startAt}
  //         reservationLines={reservationLines}
  //       />
  //     </EditItemContainer>
  //   );
}
