import { useTranslation } from "react-i18next";
import {
  minPasswordLength,
  passwordSpecialChars,
} from "@/components/settings/profile/profile-schema";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export function PasswordRequirements({
  allowEmptyPassword = false,
}: {
  allowEmptyPassword?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Alert>
      <AlertTitle>
        {t("settings.profile.password_requirements.title")}
      </AlertTitle>
      <AlertDescription>
        <p className="mb-2">
          {t("settings.profile.password_requirements.description", {
            minLength: minPasswordLength,
            specialCharacters: passwordSpecialChars,
            interpolation: { escapeValue: false },
          })}
        </p>
        {allowEmptyPassword && <p>{t("settings.profile.keep_password")}</p>}
      </AlertDescription>
    </Alert>
  );
}
