import { Calendar as CalendarIcon } from "lucide-react";
import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useDateFormat } from "@/hooks/use-date-format";
import { cn } from "@/lib/utils";

export const DatePicker = forwardRef<
  HTMLDivElement,
  {
    date?: Date;
    setDate: (date?: Date) => void;
  }
>(function DatePickerCmp({ date, setDate }, ref) {
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "pl-3 text-left flex",
            !date && "text-muted-foreground",
          )}
        >
          {date ? (
            formatDate(date, "PPP")
          ) : (
            <span>{t("date_time.date_input_placeholder")}</span>
          )}
          <CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" ref={ref}>
        <Calendar
          mode="single"
          required={true}
          selected={date}
          onSelect={(date) => {
            setOpen(false);
            setDate(date);
          }}
          autoFocus
        />
      </PopoverContent>
    </Popover>
  );
});
