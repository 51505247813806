import type { ControllerRenderProps } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { ZodNumber } from "zod";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import { getFormFieldName, getSchemaProperties } from "@/components/form/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

type FormInputProps<T extends ZodShape> =
  React.InputHTMLAttributes<HTMLInputElement> & FormFieldProps<T>;

export function FormInput<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  ...props
}: FormInputProps<T>) {
  const form = useFormContext();

  const { label, isRequired, min, max, baseSchema } = getSchemaProperties(
    schema,
    name,
  );

  let type = props.type ?? "text";
  if (baseSchema instanceof ZodNumber) {
    type = "number";
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps,
  ) {
    if (type === "number") {
      const value = event.target.valueAsNumber;
      field.onChange(isNaN(value) ? null : value);
    } else {
      field.onChange(event.target.value);
    }
  }

  return (
    <FormField
      name={getFormFieldName(path, name)}
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <FormItemLabel label={label} isRequired={isRequired} />
          <FormControl>
            <Input
              type={type}
              name={field.name}
              disabled={field.disabled}
              value={field.value ?? ""}
              ref={field.ref}
              onChange={(event) => handleChange(event, field)}
              onBlur={field.onBlur}
              {...getLengthProps(type, min, max)}
              {...props}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function getLengthProps(
  type: string,
  min: number | undefined,
  max: number | undefined,
) {
  const lengthProps: {
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
  } = {};

  if (type === "number") {
    if (min) {
      lengthProps.min = min;
    }
    if (max) {
      lengthProps.max = max;
    }
  }
  if (type === "text") {
    if (min) {
      lengthProps.minLength = min;
    }
    if (max) {
      lengthProps.maxLength = max;
    }
  }

  return lengthProps;
}
