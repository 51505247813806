import type { PropsWithChildren } from "react";
import type { FieldValues, SubmitHandler } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import type { ZodForm } from "@/components/form/types";

type FormContainerProps<T extends FieldValues> = PropsWithChildren<{
  form: ZodForm<T>;
  onSubmit: SubmitHandler<T>;
}>;

export function FormContainer<T extends FieldValues>({
  form,
  onSubmit,
  children,
}: FormContainerProps<T>) {
  // const watch = form.watch;

  // useEffect(() => {
  //   const { unsubscribe } = watch((watchedValues) => {
  //     const parsed = schema.safeParse(watchedValues);
  //     onValuesChange(parsed.success ? parsed.data : null);
  //   });
  //   return () => unsubscribe();
  // }, [schema, watch, onValuesChange]);

  return (
    <FormProvider {...form}>
      <form
        id={form.id}
        className="space-y-5"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
}
