import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { BookableDetailResponseDTO } from "@/client/private";
import { updateBookablesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import { createBookableSchema } from "@/components/bookables/bookable-schema";
import { useChannelData } from "@/components/bookables/use-channel-data";
import { useResourceData } from "@/components/bookables/use-resource-data.ts";
import {
  FormCheckboxGroup,
  FormContainer,
  FormDuration,
  FormInput,
  FormSelect,
  FormSubmit,
  FormTextArea,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditBookableFormProps {
  bookable: BookableDetailResponseDTO;
}

export function EditBookableForm({ bookable }: EditBookableFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateBookablesMutation() });
  const { channelOptions, isChannelsPending } = useChannelData();
  const { resourceOptions, isResourcePending } = useResourceData();

  const schema = createBookableSchema(t);
  const form = useZodForm({
    schema,
    values: {
      ...bookable,
      channelId: bookable.channel?.map((c) => c.id) ?? [],
      resourceId: bookable.resource?.map((r) => r.id) ?? [],
    },
  });

  const handleSubmit = (values: BookableSchemaValues) => {
    update.mutate(
      {
        path: { id: bookable.id },
        body: {
          ...values,
          capacityLabel: values.capacityLabel ?? undefined,
          resourceId: values.resourceId ?? [],
          channelId: values.channelId ?? [],
          storage: [],
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("bookables.bookable") }),
          );
          navigate({ to: "/bookables" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("bookables.bookable") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={update}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer
      title={`${t("bookables.bookable")} ${bookable.name}`}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("bookables.edit.title")}</CardTitle>
          <CardDescription>{t("bookables.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="name" schema={schema} />
            <FormTextArea name="description" schema={schema} />
            <FormInput name="capacityLabel" schema={schema} />
            <FormDuration name="duration" schema={schema} />
            <FormDuration name="slotInterval" schema={schema} />
            <FormSelect
              name="resourceUsageType"
              schema={schema}
              translateLabel={(label) =>
                t(`bookables.resource_usage_type.${label.toLowerCase()}`)
              }
            />
            <FormCheckboxGroup
              name="channelId"
              schema={schema}
              options={channelOptions}
              isPending={isChannelsPending}
              description={
                channelOptions.length === 0 && t("bookables.add.no_channels")
              }
            />
            <FormCheckboxGroup
              name="resourceId"
              schema={schema}
              options={resourceOptions}
              isPending={isResourcePending}
              description={
                resourceOptions.length === 0 && t("bookables.add.no_resources")
              }
            />
          </FormContainer>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
