import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";
import type { ListSettingDTO, listView } from "@/client/private";
import {
  getListSettingOptions,
  getListSettingQueryKey,
  upsertListSettingMutation,
} from "@/client/private/@tanstack/react-query.gen";
import type { BaseListDTO, Content, QueryFn, QueryKeyFn } from "@/types/query";

export const useDataTableSettings = <
  TResult extends Content<TResult>,
  TList extends BaseListDTO,
>(
  queryFn: QueryFn<TResult, TList>,
  queryKeyFn: QueryKeyFn<TList>,
  listSettingView: listView,
  additionalFilters: Partial<TList> = {},
) => {
  const client = useQueryClient();

  const defaultPageIndex = 0;
  const [pageIndex, setPageIndex] = useState(defaultPageIndex);

  const queryKey = getListSettingQueryKey({
    path: {
      listView: listSettingView,
    },
  });

  // Fetch list settings
  const { data: listSettings, isFetched } = useQuery({
    ...getListSettingOptions({ path: { listView: listSettingView } }),
    queryKey,
    // Only refetch after 5 minutes.
    staleTime: 1000 * 60 * 5,
  });

  // Mutate list settings
  const { mutate } = useMutation({
    ...upsertListSettingMutation(),
    onMutate: async (variables) => {
      // Optimistically update the cache with the new value,
      // causing the data query to re-fetch with the new filter settings.
      client.setQueryData(queryKey, () => {
        return {
          ...variables.body,
        };
      });
    },
  });

  // Callback used to update list settings
  const onListSettingsChange = (newListSettings: ListSettingDTO) => {
    if (listSettings && !areListSettingsEqual(listSettings, newListSettings)) {
      mutate({
        body: newListSettings,
      });
    }
  };

  // Fetch list data
  const { pageSize = 10, sort, searchTerm, filters = {} } = listSettings ?? {};
  const listReq = {
    body: {
      pagination: {
        page: pageIndex,
        size: pageSize,
      },
      sort,
      searchTerm,
      ...filters,
      ...additionalFilters,
    } as TList,
  };

  const { data, refetch, isFetching } = useQuery({
    enabled: isFetched && !!listSettings,
    queryKey: queryKeyFn(listReq),
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const { data } = await queryFn(listReq);
      return data;
    },
  });

  const content = data?.content ?? ([] as TResult["content"]);
  const totalPages = data?.page.totalPages || 0;
  const totalElements = data?.page.totalElements;

  return {
    pageIndex,
    setPageIndex,
    onListSettingsChange,
    totalPages,
    content,
    queryKey,
    listSettings,
    totalElements,
    isFetched,
    isFetching,
    refetch,
  };
};

function areListSettingsEqual(a: ListSettingDTO, b: ListSettingDTO): boolean {
  return (
    a.pageSize === b.pageSize &&
    (a.searchTerm ?? "") === (b.searchTerm ?? "") &&
    areFiltersEqual(a.filters ?? {}, b.filters ?? {}) &&
    a.sort?.direction === b.sort?.direction &&
    a.sort?.sortBy === b.sort?.sortBy
  );
}

function areFiltersEqual(
  a: Record<string, unknown>,
  b: Record<string, unknown>,
) {
  const hasSameKeyCount = Object.keys(a).length === Object.keys(b).length;
  const hasSameValues = Object.entries(a).every(
    ([key, value]) => b[key] === value,
  );
  return hasSameKeyCount && hasSameValues;
}
