import { useQuery } from "@tanstack/react-query";
import {
  getCompanyOptions,
  getCompanyQueryKey,
} from "@/client/private/@tanstack/react-query.gen.ts";
import { HALF_HOUR } from "@/lib/constants";

export const useCompany = (companyId: string | undefined) => {
  const queryKey = getCompanyQueryKey();

  const { data, isLoading } = useQuery({
    ...getCompanyOptions(),
    queryKey,
    enabled: (companyId ?? "") !== "",
    staleTime: HALF_HOUR,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};
