import { useQuery } from "@tanstack/react-query";
import { listBookablesOptions } from "@/client/private/@tanstack/react-query.gen";

export function useBookableData() {
  const { data, isPending } = useQuery({
    ...listBookablesOptions({
      body: {
        sort: {
          direction: "ASC",
          sortBy: "name",
        },
      },
    }),
  });

  const bookableOptions =
    data?.content.map(({ id, name }) => ({ title: name, value: id })) ?? [];

  return { bookableOptions, isBookablesPending: isPending };
}
