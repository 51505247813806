import type { GridLayout } from "@/components/planner/grid-layout";
import { useDateFormat } from "@/hooks/use-date-format";

type HeaderRowProps = {
  gridLayout: GridLayout;
};

export function HeaderRow({ gridLayout }: HeaderRowProps) {
  const { formatDate } = useDateFormat();

  return (
    <>
      <div className="bg-muted rounded-t-md row-start-2 row-end-2 col-span-full self-stretch"></div>
      {gridLayout.timeSlots.map((slot) => (
        <div
          key={slot.toString()}
          style={{ gridColumn: `${gridLayout.getHeaderColumn(slot)} / span 1` }}
          className="font-semibold p-2 relative w-[200%] row-start-2 row-end-2 flex flex-col items-center"
        >
          {gridLayout.zoomLevel.slotFormats.map((slotFormat) => (
            <div key={slotFormat}>{formatDate(slot, slotFormat)}</div>
          ))}
        </div>
      ))}
    </>
  );
}
