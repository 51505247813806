import { Link } from "@tanstack/react-router";
import {
  CalendarCheck,
  CalendarClock,
  Component,
  ConciergeBell,
  Construction,
  Package2,
  SatelliteDish,
  Settings,
  Users2,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";

export const SideMenu = () => {
  const { t } = useTranslation();

  const linkClassName =
    "flex h-9 w-9 items-center [&.active]:bg-accent justify-center rounded-lg text-muted-foreground [&.active]:text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8";

  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
        <Link
          to="/dashboard"
          className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"
        >
          <Package2 className="h-4 w-4 transition-all group-hover:scale-110" />
          <span className="sr-only">CBRM</span>
        </Link>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/planner" className={linkClassName}>
              <CalendarClock className="h-5 w-5" />
              <span className="sr-only">{t("planner.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{t("planner.title")}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/reservations" className={linkClassName}>
              <CalendarCheck className="h-5 w-5" />
              <span className="sr-only">{t("reservations.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">
            {t("reservations.title")}
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/bookables" className={linkClassName}>
              <ConciergeBell className="h-5 w-5" />
              <span className="sr-only">{t("bookables.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{t("bookables.title")}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/resources" className={linkClassName}>
              <Component className="h-5 w-5" />
              <span className="sr-only">{t("resources.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{t("resources.title")}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/unavailability" className={linkClassName}>
              <Construction className="h-5 w-5" />
              <span className="sr-only">{t("unavailability.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">
            {t("unavailability.title")}
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/employees" className={linkClassName}>
              <Users2 className="h-5 w-5" />
              <span className="sr-only">{t("employees.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{t("employees.title")}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/channels" className={linkClassName}>
              <SatelliteDish className="h-5 w-5" />
              <span className="sr-only">{t("channels.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{t("channels.title")}</TooltipContent>
        </Tooltip>
      </nav>
      <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to="/settings" className={linkClassName}>
              <Settings className="h-5 w-5" />
              <span className="sr-only">{t("settings.title")}</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">{t("settings.title")}</TooltipContent>
        </Tooltip>
      </nav>
    </aside>
  );
};
