import { useQuery } from "@tanstack/react-query";
import { addHours, startOfHour } from "date-fns";
import type {
  BookableResponseDTO,
  ReservationRequestResponseDTO,
} from "@/client/private";
import { listBookablesOptions } from "@/client/private/@tanstack/react-query.gen";
import type {
  PlannerBookable,
  PlannerReservationRequest,
} from "@/components/planner/planner.model";

export function useBookableRequestData() {
  const defaultPagination = {
    page: 0,
    size: 1000,
  };

  const { data: bookableData, isLoading: isBookablesLoading } = useQuery({
    ...listBookablesOptions({
      body: {
        pagination: defaultPagination,
        sort: { sortBy: "name", direction: "ASC" },
      },
    }),
  });

  const bookables = mapBookables(bookableData?.content);
  const requests = mapRequests(
    createMockRequests(bookableData?.content),
    bookables,
  );

  return {
    bookables,
    requests,
    isLoading: isBookablesLoading,
  };
}

function mapBookables(
  bookables: BookableResponseDTO[] | undefined,
): PlannerBookable[] {
  return bookables ?? [];
}

function mapRequests(
  requests: ReservationRequestResponseDTO[] | undefined,
  bookables: PlannerBookable[],
): PlannerReservationRequest[] {
  return (requests ?? []).map((request) => {
    const bookable = bookables[Math.floor(Math.random() * bookables.length)];
    return {
      ...request,
      key: `${request.id}-${bookable?.id}`, //TODO: Add bookableId
      bookableId: bookable?.id ?? "", //TODO: Add bookableId
      bookableName: bookable?.name ?? "", //TODO: Add bookableName
      capacityLabel: bookable?.capacityLabel ?? "", //TODO: Add capacityLabel
    };
  });
}

function createMockRequests(
  bookables: BookableResponseDTO[] | undefined,
): ReservationRequestResponseDTO[] {
  if (!bookables) {
    return [];
  }

  const mockData = Array.from({ length: 3 }, (_, index) => index);

  return mockData.flatMap((index) => {
    const startAt = addHours(startOfHour(new Date()), index);
    const bookable = bookables[Math.floor(Math.random() * bookables.length)]!;

    return {
      id: `${bookable.id}-${index}`,
      startAt: startAt,
      until: addHours(startAt, 1),
      name: "Mr. Test",
      email: "dummy@mail.com",
      phoneNumber: "123456789",
      capacity: Math.ceil(Math.random() * 10),
      reservationStatus: "PENDING",
      dateCreated: new Date(),
      lastUpdated: new Date(),
    };
  });
}
