import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import type { TFunction } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { z } from "zod";
import { onboardingUserMutation } from "@/client/public/@tanstack/react-query.gen.ts";
import { PasswordRequirements } from "@/components/auth/PasswordRequirements";
import {
  FormCheckbox,
  FormContainer,
  FormInput,
  FormSelect,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { createCompanySchema } from "@/components/settings/company/company-schema";
import { createAddProfileSchema } from "@/components/settings/profile/profile-schema";
import type { Languages } from "@/lib/language.ts";
import { toBackendLanguage } from "@/lib/language.ts";

function createFormSchema(t: TFunction) {
  const profileSchema = createAddProfileSchema(t).pick({
    firstName: true,
    lastName: true,
    email: true,
    passwords: true,
    language: true,
  });

  const companySchema = createCompanySchema([], t).pick({ companyName: true });

  const termsSchema = z.object({
    acceptTerms: z
      .boolean()
      .describe(t("registration.accept_terms_and_conditions"))
      .refine((value) => value, {
        message: t("registration.must_accept_terms_and_conditions"),
        path: ["acceptTerms"],
      }),
  });

  return profileSchema.merge(companySchema).merge(termsSchema);
}

export const RegisterForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const schema = createFormSchema(t);
  const form = useZodForm({ schema });

  const redirectOnSuccess = async () => {
    await navigate({ to: "/login" });
  };

  const register = useMutation({
    ...onboardingUserMutation(),
    onSuccess: redirectOnSuccess,
  });

  const handleSubmit = (values: z.infer<typeof schema>) => {
    register.mutate({
      body: {
        companyName: values.companyName,
        email: values.email,
        password: values.passwords.password,
        firstName: values.firstName,
        language: toBackendLanguage(values.language as keyof typeof Languages),
        lastName: values.lastName,
      },
    });
  };

  const termsDescription = (
    <Trans
      i18nKey="registration.agree_to_terms_and_conditions"
      components={{
        termsLink: (
          <Link
            className="text-primary underline"
            to={"/terms"}
            target={"_blank"}
          />
        ),
      }}
    />
  );

  return (
    <FormContainer form={form} onSubmit={handleSubmit}>
      <FormInput name="firstName" schema={schema} />
      <FormInput name="lastName" schema={schema} />
      <FormInput name="email" schema={schema} />
      <FormInput
        name="password"
        path={["passwords"]}
        schema={schema.shape.passwords}
        type="password"
      />
      <PasswordRequirements />
      <FormSelect name="language" schema={schema} />
      <FormInput name="companyName" schema={schema} />
      <FormCheckbox
        name="acceptTerms"
        schema={schema}
        required={true}
        description={termsDescription}
      />
      <FormSubmit form={form} mutation={register}>
        {t("registration.register")}
      </FormSubmit>
    </FormContainer>
  );
};
