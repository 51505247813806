import type {
  ColumnFilter,
  OnChangeFn,
  RowSelectionState,
} from "@tanstack/react-table";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type PaginationState,
  type SortingState,
  type VisibilityState,
} from "@tanstack/react-table";
import { useState } from "react";
import type { ListSettingDTO } from "@/client/private";
import {
  toFiltersSetting,
  toFiltersState,
  toPaginationSetting,
  toPaginationState,
  toSearchTermSetting,
  toSearchTermState,
  toSortingSetting,
  toSortingState,
} from "@/components/data-table/data-table-mappings";

export interface RequiredRowProps {
  id: string;
}

interface UseDataTableProps<TData extends RequiredRowProps, TValue> {
  data: Array<TData>;
  columns: Array<ColumnDef<TData, TValue>>;
  totalPages: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  listSettings: ListSettingDTO;
  onListSettingsChange: (newListSettings: ListSettingDTO) => void;
}

export function useDataTable<TData extends RequiredRowProps, TValue>({
  data,
  columns,
  totalPages,
  pageIndex,
  setPageIndex,
  listSettings,
  onListSettingsChange,
}: UseDataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const [pagination, setPagination] = useState<PaginationState>(
    toPaginationState(listSettings, pageIndex),
  );
  const [globalFilter, setGlobalFilter] = useState<string>(
    toSearchTermState(listSettings),
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>(
    toFiltersState(listSettings),
  );
  const [sorting, setSorting] = useState<SortingState>(
    toSortingState(listSettings),
  );

  const getRowId = (originalRow: TData, index: number) => {
    if (originalRow && Object.hasOwn(originalRow, "id")) {
      return originalRow.id;
    }
    return index.toString();
  };

  const handleListSettingsChange = (update: Partial<ListSettingDTO>) => {
    const newListSettings: ListSettingDTO = {
      ...listSettings,
      ...update,
    };
    onListSettingsChange(newListSettings);
  };

  const onGlobalFilterChange: OnChangeFn<string> = (updater) => {
    const newValue =
      updater instanceof Function ? updater(globalFilter) : updater;
    handleListSettingsChange(toSearchTermSetting(newValue));
    setGlobalFilter(newValue);
  };

  const onSortingChange: OnChangeFn<SortingState> = (updater) => {
    const newValue = updater instanceof Function ? updater(sorting) : updater;
    handleListSettingsChange(toSortingSetting(newValue));
    setSorting(newValue);
  };

  const onPaginationChange: OnChangeFn<PaginationState> = (updater) => {
    const newValue =
      updater instanceof Function ? updater(pagination) : updater;
    handleListSettingsChange(toPaginationSetting(newValue));
    setPageIndex(newValue.pageIndex);
    setPagination(newValue);
  };

  const onColumnFiltersChange: OnChangeFn<ColumnFilter[]> = (updater) => {
    const newValue =
      updater instanceof Function ? updater(columnFilters) : updater;
    handleListSettingsChange(toFiltersSetting(newValue));
    setColumnFilters(newValue);
  };

  const table = useReactTable<TData>({
    data,
    columns,
    pageCount: totalPages ?? -1,
    state: {
      columnVisibility,
      rowSelection,
      sorting: toSortingState(listSettings),
      pagination: toPaginationState(listSettings, pageIndex),
      globalFilter: toSearchTermState(listSettings),
      columnFilters: toFiltersState(listSettings),
    },
    getRowId: getRowId,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: onPaginationChange,
    onSortingChange: onSortingChange,
    onColumnFiltersChange: onColumnFiltersChange,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: onGlobalFilterChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableRowSelection: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableGlobalFilter: false,
  });

  return { table };
}
